import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaEye } from "react-icons/fa";
import ViewCharges from "./ViewCharges";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import { useNavigate } from "react-router-dom";
import AddCharges from "./AddCharges";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import CustomActInBox from "../../Common/Custom Confirm/CustomActInBox";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [char, setChar] = useState(false);
  const [viewChar, setViewChar] = useState(false);
  const ViewCharHandle = () => {
    setViewChar((val) => !val);
  };
  const handleViewCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setViewChar((val) => !val);
  };
  const addCharHandle = () => {
    setChar((val) => !val);
  };
  const handleCharEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChar((val) => !val);
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleStatusChange = (selectedRow) => {
    console.log("rowIndex", selectedRow);
    setRowToEdit(selectedRow);

    const confirmAction = window.confirm(
      `Are you sure you want to ${
        selectedRow.is_active ? "deactivate" : "activate"
      } ${selectedRow.name}?`
    );

    if (confirmAction) {
      updateDelMutation.mutate();
    }
  };
  console.log("rowData", rowData);
  const navigate = useNavigate();

  const handleEdit = (val) => {
    console.log("val", val);
    navigate("/add-lsp-executive", {
      state: { val },
    });
  };

  const handleView = (val) => {
    console.log("val", val);
    navigate("/lsp-details", {
      state: { val },
    });
  };
  const updateDelMutation = useCustomMutation({
    url: `admin/update-lsp-executive-status/${selectedItem?.id}`,
    method: "get",
    invalidateKey: "langList",

    successCallback: () => {},
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteHandler = (data) => {
    setSelectedItem(data);
    handleClickOpen();
    console.log(data);
  };

  const deleteConfirmHandler = () => {
    console.log("selectedData", selectedItem);
    updateDelMutation.mutate(selectedItem);
    handleClose();
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {" "}
      {open && (
        <CustomActInBox
          data={selectedItem}
          onCancel={handleClose}
          onConfirm={deleteConfirmHandler}
          message={`Are you sure you want to ${
            selectedItem.is_active ? "deactivate" : "activate"
          } ${selectedItem.name}`}
          open={open}
        />
      )}
      <div className={styles.tabwrap}>
        {" "}
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th
                  style={{
                    minWidth: "140px",

                    position: "sticky",
                    left: "0",
                    backgroundColor: "#12468a",
                    backgroundImage:
                      "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                  }}
                >
                  Name
                </th>
                <th>Executive Type</th>
                <th>Branch Location</th>
                <th>Mobile</th>
                <th>Alternative Number</th>
                <th>Email</th>
                <th>City</th>
                <th>Pincode</th>
                <th>District</th>
                <th>state</th>
                <th>PAN</th>

                <th>Address</th>

                <th> Created At</th>
                {perm?.includes("update") && <th>Status</th>}

                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  {/* <td>{row.id}</td> */}
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      position: "sticky",
                      left: "0",
                      background: "#fff",
                    }}
                  >
                    {row.name}
                  </td>
                  <td>{row.executive_type}</td>
                  <td>{row.branch_location?.location_name}</td>

                  <td>{row.mobile}</td>

                  <td>{row.alternative_number}</td>
                  <td>{row.email}</td>
                  <td>{row.city}</td>

                  <td>{row.pincode}</td>
                  <td>{row.district}</td>
                  <td>{row.state?.state}</td>
                  <td>{row.pan}</td>
                  <td>{row.address}</td>
                  <td>{row.created_at}</td>
                  {perm?.includes("update") && (
                    <td>
                      {row?.is_active === true ? (
                        <button
                          // title="Inactivate Status"
                          type="button"
                          onClick={() => deleteHandler(row)}
                          style={{ minWidth: "70px" }}
                          className={styles.accessbtn}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          type="button"
                          // title="Activate Status"
                          onClick={() => deleteHandler(row)}
                          className={styles.accessbtnnegativ}
                          style={{ minWidth: "70px" }}
                        >
                          Inactive
                        </button>
                      )}
                    </td>
                  )}

                  <td>
                    {" "}
                    {perm?.includes("update") && (
                      <button
                        title="Edit Executive"
                        type="button"
                        onClick={() => handleEdit(row)}
                        className={styles.editbtn}
                      >
                        <AiFillEdit
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    <button
                      title="View Executive Details"
                      type="button"
                      className={styles.viewbtn}
                      onClick={() => handleView(row)}
                    >
                      <FaEye
                        size={12}
                        style={{ position: "relative", top: "1px" }}
                      />
                    </button>
                    {/* {perm?.includes("delete") && (
                      <button
                        title="Delete"
                        type="button"
                        className={styles.dltbtn}
                      >
                        <MdDelete
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
        {char && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={char}
            onClick={addCharHandle}
          >
            <AddCharges
              addHandle={addCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
        {viewChar && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={viewChar}
            onClick={ViewCharHandle}
          >
            <ViewCharges
              addHandle={ViewCharHandle}
              defaultValue={rowToEdit || []}
            />
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default Table;
