import React, { useContext, useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Button, Box } from "@mui/material";
import SelectType from "./Step1";
import Breadcum from "../../Common/Breadcum";
import classes from "./Language.module.css";
import Step2 from "./Step2";
import Step3 from "./Step3";

import Step1 from "./Step1";
import api from "../../Common/api";
import { LoanContext } from "../../../context/LoanContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../../context/ToastProvder";
import { useQuery } from "@tanstack/react-query";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
import Step10 from "./Step10";
import Step11 from "./Step11";
import Step12 from "./Step12";
import Step14 from "./Step14";
import Step13 from "./Step13";
import Step16 from "./Step16";
import Step15 from "./Step15";
import Step17 from "./Step17";
import Step18 from "./Step18";
import Step19 from "./Step19";
import Step20 from "./Step20";
import Step21 from "./Step21";
import Step22 from "./Step22";
import Step23 from "./Step23";
import GuaEign from "./GuaEign";
import Step27 from "./Step27";
import Step28 from "./Step28";
const steps = [
  "1: ",
  "2:",
  "3: ",
  "4:",
  "5:",
  "6:",
  "7:",
  "8:",
  "9:",
  "10:",
  "11:",
  "12:",
  "13:",
  "14:",
  "15:",
  "16:",
  "17:",
  "18:",
  "19:",
  "20:",
  "21:",
  "22:",
  "23:",
  "24:",
  "25:",
  "26:",
];

const ApplyLoanDy = () => {
  const {
    activeStep,
    setActiveStep,
    userD,
    setUserD,
    borrId,
    setBorrId,
    dataFromAdhar,
    setDataFromAdhar,
    proId,
    setProId,
    userId,
    setUserId,
    activeSubmenuId,
  } = useContext(LoanContext);
  const [perm, setPerm] = useState([]);
  const { showToast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { row } = location.state || "";
  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-sub-menu-permissions/${activeSubmenuId}`
    );
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions", activeSubmenuId],
    queryFn: fetchPermissions,
    enabled: !!activeSubmenuId,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  useEffect(() => {
    if (
      !row
      // || !row.borrower_id
    ) {
      navigate("/all-user-list");
    }
  }, [row, navigate]);
  useEffect(() => {
    if (row) {
      setActiveStep(0);
    }
    if (row && row.borrower_id) {
      setBorrId(row.borrower_id);
      setUserId(row.user_id);
      api
        .post("admin/get-loan-status", {
          borrower_id: row.borrower_id,
        })
        .then((response) => {
          // console.log("Loan Status:", response.data);

          // const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          if (step === 21) {
            setActiveStep(21);
          } else if (step === 22) {
            setActiveStep(22);
          } else if (step === 23) {
            setActiveStep(22);
          } else if (step === 24) {
            setActiveStep(23);
          } else if (step === 25) {
            setActiveStep(23);
          } else if (step === 26) {
            setActiveStep(24);
          } else if (step === 27) {
            setActiveStep(25);
          } else if (step) {
            setActiveStep(step + 1);
          } else if (step === 28) {
            showToast("Your Application is Submitted Successfully", "success");
            navigate("/all-user-list");
          } else {
            setActiveStep(0);
          }
        })
        .catch((error) => {
          // console.error("Error fetching loan status:", error);
        });
    } else if (row && row.user_id) {
      setUserId(row.user_id);
      setActiveStep(1);
    }
  }, [row]);
  const handleStepClick = (step) => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  // console.log("userD", userD);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // console.log("borrId", borrId);
  // console.log("activeStep", activeStep);
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Step1
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            setUserD={setUserD}
          />
        );
      case 1:
        return (
          <Step2
            userD={userD}
            handleNext={handleNext}
            setUserD={setUserD}
            setBorrId={setBorrId}
          />
        );

      case 2:
        return (
          <Step3
            borrId={borrId}
            userD={userD}
            handleNext={handleNext}
            setDataFromAdhar={setDataFromAdhar}
            dataFromAdhar={dataFromAdhar}
          />
        );
      case 3:
        return <Step4 />;
      case 4:
        return <Step5 />;
      case 5:
        return <Step6 />;
      case 6:
        return <Step7 />;
      case 7:
        return <Step8 />;
      case 8:
        return <Step9 />;
      case 9:
        return <Step10 />;
      case 10:
        return <Step11 />;
      case 11:
        return <Step12 />;
      case 12:
        return <Step13 />;
      case 13:
        return <Step14 />;
      case 14:
        return <Step15 />;
      case 15:
        return <Step16 />;
      case 16:
        return <Step17 />;
      case 17:
        return <Step18 />;
      case 18:
        return <Step19 />;
      case 19:
        return <Step20 />;
      case 20:
        return <Step21 />;
      case 21:
        return <Step22 />;
      case 22:
        return <Step23 />;
      case 23:
        return <GuaEign />;
      case 24:
        return <Step27 />;
      case 25:
        return <Step28 />;
      default:
        return "Unknown Step";
    }
  };

  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <Breadcum pageName="Apply Loan" />
      <div className={classes.mainBodyContainer}>
        {/* {perm?.includes("create") && ( */}
        <div
          className={classes["main-body"]}
          style={{ padding: "15px", background: "#fff" }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index} onClick={() => handleStepClick(index)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box mt={2}>{getStepContent(activeStep)}</Box>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default ApplyLoanDy;
