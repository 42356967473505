import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useToast } from "../../../context/ToastProvder";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import MultiSelectInput from "../../Common/MultInputSelect/MultiInputSelect";
import useCustomMutation from "../../CustomHook/useCustomMutation";
const SearchComp = ({
  onSearch,
  filterD,
  setProduct,
  setMonth,
  setStatus,
  setStep,
}) => {
  const { register, handleSubmit, reset, watch, errors, control, setValue } =
    useForm();
  const [{ data: lenderList }, { data: borrowerList }] = useQueries({
    queries: [
      {
        queryKey: ["userType"],
        queryFn: () => api.get("admin/lender-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["borrowersc"],
        queryFn: () => api.get("dealer-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const onSubmit = async (data) => {
    let payload;
    payload = {
      // executive_id: val?.id,
      dealer_id: data?.dealer_id,
      lender_id: data?.lender_id,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: "admin/store-lender-dealer-group",
    // url: defaultValue
    //   ? `admin/get-dealers-by-lsp-executive/${val.id}`
    //   : "admin/store-lsp-executive-dealer-grouping",
    method: "post",
    // method: defaultValue ? "put" : "post",
    invalidateKey: "langList",
    successCallback: () => {
      reset();
    },
  });
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div
          className={styles.threeinputads}
          style={{ marginTop: "10px", width: "700px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Lender
            </label>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ListWithSearch
                  options={lenderList?.data || []}
                  getOptionLabel={(val) => val.name || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{`${option.name}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    lenderList?.data?.find(
                      (employee) => employee.id === value
                    ) || null
                  }
                />
              )}
              name={`lender_id`}
            />
          </div>
        </div>
        <div
          className={styles.threeinputads}
          style={{ marginTop: "10px", width: "700px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealers
            </label>

            <div style={{ minHeight: "29px" }}>
              <Controller
                name={`dealer_id`}
                control={control}
                rules={{ required: "This Field is Required." }}
                defaultValue={[]}
                render={({ field }) => (
                  <MultiSelectInput
                    // options={
                    //   borrowerList?.data?.map((item) => ({
                    //     label: item?.name, // Use borrower_id as the label
                    //     id: item?.id, // Use id as the value
                    //   })) || []
                    // }
                    options={
                      borrowerList?.data?.map((item) => ({
                        label: `${item?.name} (${item?.dealer_unq})`, // Include dealer_unq in parentheses
                        id: item?.id,
                      })) || []
                    }
                    getOptionLabel={(val) => val?.label} // Display borrower_id in the dropdown
                    getOptionSelected={(option, value) =>
                      option?.id === value?.id
                    }
                    onChange={(content, value) => {
                      const selectedIds = value?.map((item) => item?.id); // Get selected IDs
                      field.onChange(selectedIds); // Update form field
                    }}
                    value={field.value.map((id) => ({
                      id,
                      label:
                        borrowerList?.data?.find((item) => item?.id === id)
                          ?.name || "",
                    }))}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.label} {/* Render borrower_id */}
                      </li>
                    )}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
