import React, { useContext, useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaEye } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop, Dialog } from "@mui/material";
import CustomActInBox from "../../Common/Custom Confirm/CustomActInBox";
import useCustomMutation from "../../CustomHook/useCustomMutation";

import { Navigate, useNavigate } from "react-router-dom";
// import AddModal from "./AddModal";
import { AuthContext } from "../../../context/AuthContext";
import RemarkView from "./RemarkView";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [ifscD, setIfscD] = useState(false);
  const [re, setRe] = useState(false);
  const [type, setType] = useState(null);
  const [viewM, setViewM] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const { baseDoc } = useContext(AuthContext);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const addifsc = () => {
    setIfscD((val) => !val);
  };
  const handleViewImage = (imagePath) => {
    setSelectedImage(`${baseDoc}/${imagePath}`);
    setShowImageModal(true);
  };
  const closeImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };
  const addViewHandle = () => {
    setViewM((val) => !val);
  };
  const reHandle = () => {
    setRe((val) => !val);
  };
  const handleEditRow = (val) => {
    console.log("val", val);
    navigate("/add-subvention-details", {
      state: { val },
    });
  };
  const handleifscRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setIfscD((val) => !val);
  };
  const HandleApprove = (id, type) => {
    setRowToEdit(id);
    setType(type);
    setSelectedItem(id);

    setRe((val) => !val);
  };
  const HandleReject = (id, type) => {
    setRowToEdit(id);

    setRe((val) => !val);
    setType(type);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const HandleView = (id) => {
    setRowToEdit(id);

    setViewM((val) => !val);
  };
  const deleteConfirmHandler = () => {
    console.log("selectedData", selectedItem);
    updateDelMutation.mutate(selectedItem);
    handleClose();
  };
  const updateDelMutation = useCustomMutation({
    url: `admin/update-subvention-detail-status/${selectedItem?.id}`,
    method: "post",
    invalidateKey: "langList",
    // setErr: setErr,
    successCallback: () => {
      // reset();
      // addHandle();
    },
  });
  const deleteHandler = (data) => {
    setSelectedItem(data);
    handleClickOpen();
    console.log(data);
  };
  console.log("selectedItem", selectedItem);
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {" "}
      {open && (
        <CustomActInBox
          data={selectedItem}
          onCancel={handleClose}
          onConfirm={deleteConfirmHandler}
          message={`Are you sure you want to ${
            selectedItem.status === "active" ? "deactivate" : "activate"
          } `}
          open={open}
        />
      )}
      <div className={styles.tabwrap}>
        {" "}
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Borrower ID</th>
                <th>Borrower Name</th>
                <th>Mobile</th>
                <th>Disbursement Amount</th>

                <th>Request Date</th>

                <th>Created On</th>
                <th>Created By</th>
                <th>Updated On</th>
                <th>Updated By</th>
                <th>Status</th>
                <th>Request Remarks</th>
                <th>Admin Remarks</th>
                {/* 
                <th> Action</th> */}
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.borrower_unq}</td>
                  <td>{row.borrower_name}</td>
                  <td>{row.borrower_mobile}</td>
                  <td>{row.disbursement_amount}</td>

                  <td>{row.request_date}</td>

                  <td>{row.created_at}</td>
                  <td>{row?.request_by?.name}</td>
                  <td>{row.updated_at}</td>
                  <td>{row.updated_by}</td>
                  <td>{row.status}</td>

                  <td>
                    {row.request_remarks && (
                      <button
                        type="button"
                        title="Remark"
                        onClick={() => HandleView(row?.request_remarks)}
                        className={styles.viewbtn}
                      >
                        <FaEye
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                  </td>
                  <td>
                    {row.admin_remarks && (
                      <button
                        type="button"
                        title="Remark"
                        onClick={() => HandleView(row?.admin_remarks)}
                        className={styles.viewbtn}
                      >
                        <FaEye
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                  </td>

                  {/* <td>
                    {" "}
                    {perm?.includes("update") && (
                      <button
                        title="Edit"
                        type="button"
                        onClick={() => handleEditRow(row)}
                        className={styles.editbtn}
                      >
                        <AiFillEdit
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                   
                
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={viewM}
          onClick={addViewHandle}
        >
          <RemarkView
            addHandle={addViewHandle}
            defaultValue={rowToEdit || ""}
          />
        </Backdrop>
        <Dialog open={showImageModal} onClose={closeImageModal} maxWidth="sm">
          <img
            src={selectedImage}
            style={{ width: "500px", height: "auto", maxHeight: "500px" }}
          />
        </Dialog>
      </div>
    </div>
  );
};

export default Table;
