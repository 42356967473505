import { useContext } from "react";
import styles from "../AllUser/Modal.module.css";

import close from "./../../../icons/close.png";
import { AuthContext } from "../../../context/AuthContext";

const ViewImgg = ({ addHandle, defaultValue }) => {
  const { baseDoc } = useContext(AuthContext);
  console.log("defaultValue dafs", defaultValue);
  const getFullUrl = `${baseDoc}${defaultValue}`;
  return (
    <form
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      {/* <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}></h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div> */}

      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img src={getFullUrl} style={{ width: "500px", height: "500px" }} />
        </div>
      </div>
    </form>
  );
};

export default ViewImgg;
