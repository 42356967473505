import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";

const RemarkM = ({ addHandle, defaultValue, type }) => {
  const [err, setErr] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      agreement_download: 0,
      borrower_doc_download: 0,
      cam_sheet_download: 0,
      vkyc_report_download: 0,
      bureau_borrower_download: 0,
      bureau_coapplicant_download: 0,
      bureau_guarantor_download: 0,
    },
  });
  console.log({ type });
  console.log({ defaultValue });
  const onSubmit = async (data) => {
    let payload;
    payload = {
      status: type,
      remarks: data.remarks,
      borrower_doc_download: data.borrower_doc_download,
      agreement_download: data.agreement_download,
      cam_sheet_download: data.cam_sheet_download,
      vkyc_report_download: data.vkyc_report_download,
      bureau_borrower_download: data.bureau_borrower_download,
      bureau_guarantor_download: data.bureau_guarantor_download,
      bureau_coapplicant_download: data.bureau_coapplicant_download,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: `admin/handle-lender-download-request/${defaultValue}`,
    method: defaultValue ? "post" : "post",
    invalidateKey: "bankDetailsList",
    setErr: setErr,
    successCallback: () => {
      reset();
      addHandle();
    },
  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "750px" }}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div
        className={styles.modalHeader}
        style={{ padding: "5px", maxHeight: "5px" }}
      >
        <h3 className={styles.modalheading}></h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              <label
                style={{
                  marginBottom: "1rem",
                  color: "#494646",
                  fontSize: "13px",
                }}
              >
                Remarks
                {/* {type === "APPROVE" ? "For Approval" : "For Rejection"} */}
              </label>
              <textarea
                style={{ width: "95%" }}
                {...register("remarks", { required: true })}
                className={styles.mdselct}
              />
            </div>
            {type === "APPROVED" && (
              <div
                className={styles.inptconnt}
                style={{ marginBottom: "0.5rem" }}
              >
                <label
                  style={{
                    marginBottom: "1rem",
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  No of Download(Borrower Documents)
                </label>
                <input
                  type="number"
                  style={{ width: "95%", height: "20px" }}
                  {...register("borrower_doc_download", {
                    required: "This Field is Required.",
                    min: {
                      value: 0,
                      message: "Value Cannot be Negative",
                    },
                  })}
                  className={styles.mdselct}
                />
                {errors.borrower_doc_download && (
                  <span className={styles.error}>
                    {errors.borrower_doc_download.message}
                  </span>
                )}
              </div>
            )}
            {type === "APPROVED" && (
              <div
                className={styles.inptconnt}
                style={{ marginBottom: "0.5rem" }}
              >
                <label
                  style={{
                    marginBottom: "1rem",
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  No of Download(Agreement)
                </label>
                <input
                  type="number"
                  style={{ width: "95%", height: "20px" }}
                  {...register("agreement_download", {
                    required: "This Field is Required.",
                    min: {
                      value: 0,
                      message: "Value Cannot be Negative.",
                    },
                  })}
                  className={styles.mdselct}
                />
                {errors.agreement_download && (
                  <span className={styles.error}>
                    {errors.agreement_download.message}
                  </span>
                )}
              </div>
            )}
            {type === "APPROVED" && (
              <div className={styles.inptconnt}>
                <label
                  style={{
                    marginBottom: "0.5rem",
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  No of Download(CAM Sheet)
                </label>
                <input
                  type="number"
                  style={{ width: "95%", height: "20px" }}
                  {...register("cam_sheet_download", {
                    required: "This Field is Required.",
                    min: {
                      value: 0,
                      message: "Value Cannot be Negative",
                    },
                  })}
                  className={styles.mdselct}
                />
                {errors.cam_sheet_download && (
                  <span className={styles.error}>
                    {errors.cam_sheet_download.message}
                  </span>
                )}
              </div>
            )}
            {type === "APPROVED" && (
              <div className={styles.inptconnt}>
                <label
                  style={{
                    marginBottom: "0.5rem",
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  No of Download(VKYC Report)
                </label>
                <input
                  type="number"
                  style={{ width: "95%", height: "20px" }}
                  {...register("vkyc_report_download", {
                    required: "This Field is Required.",
                    min: {
                      value: 0,
                      message: "Value Cannot be Negative",
                    },
                  })}
                  className={styles.mdselct}
                />
                {errors.vkyc_report_download && (
                  <span className={styles.error}>
                    {errors.vkyc_report_download.message}
                  </span>
                )}
              </div>
            )}
            {type === "APPROVED" && (
              <div className={styles.inptconnt}>
                <label
                  style={{
                    marginBottom: "0.5rem",
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  No of Download(Borrower Bureau Report)
                </label>
                <input
                  type="number"
                  style={{ width: "95%", height: "20px" }}
                  {...register("bureau_borrower_download", {
                    required: "This Field is Required.",
                    min: {
                      value: 0,
                      message: "Value Cannot be Negative",
                    },
                  })}
                  className={styles.mdselct}
                />
                {errors.bureau_borrower_download && (
                  <span className={styles.error}>
                    {errors.bureau_borrower_download.message}
                  </span>
                )}
              </div>
            )}
            {type === "APPROVED" && (
              <div className={styles.inptconnt}>
                <label
                  style={{
                    marginBottom: "0.5rem",
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  No of Download(Coapplicant Bureau Report)
                </label>
                <input
                  type="number"
                  style={{ width: "95%", height: "20px" }}
                  {...register("bureau_coapplicant_download", {
                    required: "This Field is Required.",
                    min: {
                      value: 0,
                      message: "Value Cannot be Negative",
                    },
                  })}
                  className={styles.mdselct}
                />
                {errors.bureau_coapplicant_download && (
                  <span className={styles.error}>
                    {errors.bureau_coapplicant_download.message}
                  </span>
                )}
              </div>
            )}
            {type === "APPROVED" && (
              <div className={styles.inptconnt}>
                <label
                  style={{
                    marginBottom: "0.5rem",
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  No of Download(Guarantor Bureau Report)
                </label>
                <input
                  type="number"
                  style={{ width: "95%", height: "20px" }}
                  {...register("bureau_guarantor_download", {
                    required: "This Field is Required.",
                    min: {
                      value: 0,
                      message: "Value Cannot be Negative",
                    },
                  })}
                  className={styles.mdselct}
                />
                {errors.bureau_guarantor_download && (
                  <span className={styles.error}>
                    {errors.bureau_guarantor_download.message}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <p></p> */}
          <div style={{ display: "flex", gap: "10px" }}>
            {/* <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button> */}
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RemarkM;
