import React from "react";
import { useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch } = useForm();
  const onSubmit = (data) => {
    console.log("searchForm submitted:", data);
    onSearch(data);
  };
  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const [{ data: departmentList }] = useQueries({
    queries: [
      {
        queryKey: ["branchlist"],
        queryFn: () => api.get("product-types").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Product Type
            </label>

            <select
              {...register("product_type_id")}
              className={styles.inpttsc}
              style={{ height: "29px" }}
            >
              <option value="">--Select Product Type--</option>
              {departmentList?.data?.map((row, i) => (
                <option key={i} value={row.id}>
                  {row.product_type}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Min Loan Amount
            </label>
            <input
              type="number"
              step="0.01"
              className={styles.inpttsc}
              {...register("min_loan_amount")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Max Loan Amount
            </label>
            <input
              type="number"
              step="0.01"
              className={styles.inpttsc}
              {...register("max_loan_amount")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Min Loan Tenure
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("min_loan_tenure")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Max Loan Tenure
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("max_loan_tenure")}
            />
          </div>
        </div>

        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
