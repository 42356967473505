import React, { useContext, useEffect, useState } from "react";
import classes from "./Dashboard.module.css";
import SearchDashboard from "./SearchDashboard";
import MonthBtn from "./MonthBtn";
import TotalDash from "./TotalDash";
import BottomDetails from "./BottomDetails";
import DashChart from "./DashChart";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import YearTotalDash from "./YearTotalDash";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import OtherChart from "./OtherChart";
import Donut from "./Donut";
import BarChart from "./BarChart";
import PointSty from "./PointSty";

const MainDashboad = () => {
  const [searchData, setSearchData] = useState(null);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState("");
  useEffect(() => {
    if (user?.user_type === "OEM") {
      navigate("/oem-dashboard");
    } else if (user?.user_type === "Lender") {
      navigate("/lender-dashboard");
    } else if (user?.user_type === "Lender Executive") {
      navigate("/lender-dashboard");
    } else if (user?.user_type === null) {
      navigate("/");
    }
  }, [user, navigate]);
  const fetchData = async (searchData, selectedMonth) => {
    const payload = { month: selectedMonth };

    if (searchData) {
      payload.bankId = searchData.bankId || "";
    }

    const response = await api.get("get-dashboard-details", {
      params: payload,
    });
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList", searchData, selectedMonth],
    queryFn: () => fetchData(searchData, selectedMonth),
    onSuccess: (data) => {},
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;

  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <div className={classes["main-body"]}>
          <SearchDashboard />
          <YearTotalDash />
          <MonthBtn
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
          />
          <TotalDash
            dashboardList={dashboardList}
            selectedMonth={selectedMonth}
          />
          <DashChart
            dashboardList={dashboardList}
            selectedMonth={selectedMonth}
          />
          <BottomDetails />
          {/* <div
            style={{
              display: "grid",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                whiteSpace: "nowrap",
                gap: "10px",
                margin: "10px 0",
              }}
            >
              <div className={classes.shdbds} style={{ width: "73.5%" }}></div>
              <div style={{ width: "24%" }}>
                {" "}
                <Donut />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                whiteSpace: "nowrap",
                gap: "10px",
                margin: "10px 0",
                flexWrap: "nowrap", // Prevent wrapping
                width: "100%", // Ensure the container takes full width
                overflowX: "auto",
              }}
            >
              <OtherChart />
              <OtherChart />
              <Donut />
            
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                whiteSpace: "nowrap",
                gap: "10px",
                margin: "10px 0",
              }}
            >
              <BarChart />
              <PointSty />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MainDashboad;
