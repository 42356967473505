import React, { useEffect, useState } from "react";
import styles from "../User/Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();
  const password = watch("password");
  const [err, setErr] = useState({});

  const [{ data: userTypeList }] = useQueries({
    queries: [
      {
        queryKey: ["userType"],
        queryFn: () => api.get("product-types").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (defaultValue) {
      setValue("max_loan_amount", defaultValue?.max_loan_amount);
      setValue("min_loan_amount", defaultValue.min_loan_amount);
      setValue("min_loan_tenure", defaultValue.min_loan_tenure);
      setValue("max_loan_tenure", defaultValue?.max_loan_tenure);
      setValue("product_type_id", defaultValue.product_type_id);
    }
  }, [defaultValue, setValue, userTypeList]);
  const onSubmit = async (data) => {
    let payload;

    payload = {
      max_loan_amount: data?.max_loan_amount,
      min_loan_amount: data.min_loan_amount,
      min_loan_tenure: data.min_loan_tenure,
      max_loan_tenure: data.max_loan_tenure,
      product_type_id: data.product_type_id,
    };

    // console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };
  // const updateVpnMutation = useCustomMutation({
  //   url: defaultValue ? `update-user/${defaultValue.id}` : "admin/register",
  //   method: defaultValue ? "put" : "post",
  //   invalidateKey: "userlist",
  //   successCallback: () => {
  //     reset();
  //     // addHandle();
  //   },
  // });
  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `admin/update-loan-amount-rule/${defaultValue.id}`
      : "admin/store-loan-amount-rule",
    method: "post",
    invalidateKey: "userlist",
    successCallback: () => {
      reset();
      addHandle();
    },
    setErr: setErr,
  });
  console.log("Err", err);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "520px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Edit Loan Amount Rule" : "Add Loan Amount Rule"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          paddingLeft: "15px",
          paddingTop: "5px",
        }}
      >
        <div className={styles.modalform}>
          <lebel
            style={{
              marginBottom: "3px",
              color: "#494646",
              fontSize: "13px",
            }}
          >
            Product Type
          </lebel>
          <select
            style={{ width: "235px" }}
            {...register("product_type_id", { required: true })}
            className={styles.mdselecttt}
          >
            <option value="" className={styles.optionnsel}>
              --Select Product Type--
            </option>
            {userTypeList?.data?.map((val) => (
              <option key={val.id} value={val.id}>
                {val.product_type}
              </option>
            ))}
          </select>

          {errors.product_type_id && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          {" "}
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Min Loan Amount
            </lebel>
            <input
              {...register("min_loan_amount", { required: true })}
              type="number"
              step="0.01"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.min_loan_amount && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Max Loan Amount
            </lebel>
            <input
              {...register("max_loan_amount", { required: true })}
              type="number"
              step="0.01"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.max_loan_amount && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Min Loan Tenure
            </lebel>
            <input
              {...register("min_loan_tenure", { required: true })}
              type="number"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />

            {errors.min_loan_tenure && (
              <span className={styles.error}>{errors.email.message}</span>
            )}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Max Loan Tenure
            </lebel>
            <input
              {...register("max_loan_tenure", { required: true })}
              type="number"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />

            {errors.max_loan_tenure && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
