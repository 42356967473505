import React, { useState, useEffect } from "react";
import styles from "./MonthBtn.module.css";
import { TbHexagons } from "react-icons/tb";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
const YearTotalDash = () => {
  const handleSignIn = (val) => {};
  const fetchData = async (searchData) => {
    const payload = {};

    if (searchData) {
      payload.bankId = searchData.bankId || "";
    }

    const response = await api.get("get-yearly-count");
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["yearCount"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {},
  });
  const { data: yearCount } = DetailsResponse;

  return (
    <div style={{ marginTop: "20px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        {/* {applicationData.map((app, index) => ( */}
        <div className={styles.ttldv} onClick={() => handleSignIn("")}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                marginBottom: "3px",
              }}
            >
              Total Application(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.total_application}
              </p>
              <div
                style={{
                  background: "#299cdb",
                  backgroundImage: `
    radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    )
  `,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={23} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("1")}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                marginBottom: "3px",
              }}
            >
              Registration(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step1_count}
              </p>
              <div
                style={{
                  background: "#25a546",
                  backgroundImage: `
    radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    )
  `,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={23} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("2")}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                marginBottom: "3px",
              }}
            >
              KYC(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step2_count}
              </p>
              <div
                style={{
                  background: "#45cb85",
                  backgroundImage: `
    radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    )
  `,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={23} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("3")}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                marginBottom: "3px",
              }}
            >
              Agreement Sign(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step3_count}
              </p>
              <div
                style={{
                  background: "#f06548",
                  backgroundImage: `
    radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    )
  `,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={23} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("4")}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                marginBottom: "3px",
              }}
            >
              PDD(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.step4_count}
              </p>
              <div
                style={{
                  background: "#ffbe0b",
                  backgroundImage: `
    radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    )
  `,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={23} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv} onClick={() => handleSignIn("4")}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                marginBottom: "3px",
              }}
            >
              Disbursed(YTD)
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.disbursed_application}
              </p>
              <div
                style={{
                  background: "#172b4d",
                  backgroundImage: `
    radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 87% at 26% 20%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    )
  `,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={23} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p
              style={{
                color: "#878a99",
                fontSize: "11px",
                marginBottom: "3px",
              }}
            >
              Loan Amount
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "15px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                {yearCount?.total_loan_amount}
              </p>
              <div
                style={{
                  background: "#ffbe0b",
                  backgroundImage: ` radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%,transparent 86.18%),radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%,rgba(255, 255, 255, 0) 69.79%,rgba(255, 255, 255, 0) 100% )`,
                }}
                className={styles.icocont}
              >
                <TbHexagons size={23} />
              </div>
            </div>
          </div>
        </div>
        {/* ))} */}
      </div>
    </div>
  );
};

export default YearTotalDash;
