import React, { useContext, useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import api from "../../Common/api";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Backdrop } from "@mui/material";
import ViewImg from "../CreateDealer/ViewImg";
import { AuthContext } from "../../../context/AuthContext";

const FieldData = ({ addHandle, defaultValue }) => {
  const { baseDoc } = useContext(AuthContext);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [add, setAdd] = useState(false);
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };

  const addZHandle = () => {
    setAdd((val) => !val);
  };
  return (
    <div
      className={styles.modalcontainer}
      style={{ width: "800px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addZHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Field Inspection Details</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div style={{ display: "flex", gap: "12px" }}>
        <div style={{ margin: "10px", width: "180px", height: "300px" }}>
          <img
            onClick={() => handleEditRow(defaultValue.field_inspection_image)}
            src={`${baseDoc}${defaultValue?.field_inspection_image}`}
            style={{ width: "165px", height: "285px" }}
          />
        </div>
        <div>
          <div
            className={styles.modalbody}
            style={{ borderBotom: "1px solid #red" }}
          >
            <div
              className={styles.modalform}
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "0.7rem",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "180px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  Name
                </label>
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  {defaultValue?.name || "-"}
                </label>
              </div>
              <div
                style={{
                  width: "180px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  Mobile
                </label>
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  {defaultValue?.mobile || "-"}
                </label>
              </div>
              <div
                style={{
                  width: "180px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  Field Verification Status
                </label>
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  {defaultValue?.field_verification_status
                    ? "Successful"
                    : "Pending"}
                </label>
              </div>
            </div>
          </div>
          <div
            className={styles.modalbody}
            style={{ borderBotom: "1px solid #red" }}
          >
            <div
              className={styles.modalform}
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "0.7rem",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "180px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  Verification Time
                </label>
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  {defaultValue?.verification_time || "-"}
                </label>
              </div>
              <div
                style={{
                  width: "180px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  Latitude
                </label>
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  {defaultValue?.latitude || "-"}
                </label>
              </div>
              <div
                style={{
                  width: "180px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  Longitude
                </label>
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  {defaultValue?.longitude || "-"}
                </label>
              </div>
            </div>
          </div>
          <div
            className={styles.modalbody}
            style={{ borderBotom: "1px solid #red" }}
          >
            <div
              className={styles.modalform}
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "0.7rem",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "180px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  Distance
                </label>
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  {defaultValue?.distance || "-"}
                </label>
              </div>
              <div
                style={{
                  width: "300px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  Address
                </label>
                <label
                  style={{
                    color: "#494646",
                    fontSize: "13px",
                    fontWeight: "normal",
                  }}
                >
                  {defaultValue?.address || "-"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldData;
