import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import classes from "./Language.module.css";
import BorrowerFamily from "./BorrowerFamily";
// import BorrowerOccupation from "./BorrowerOccupation";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
const Step4 = () => {
  const { borrId } = useContext(LoanContext);
  const [familyStep, setFamilyStep] = useState(false);

  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //  console.log("Loan Status:", response.data);

          const { step } = response?.data?.step_details;
        })
        .catch((error) => {
          //  console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  return (
    <div className={classes.tablco} style={{ borderBottom: "1px solid #ddd" }}>
      <BorrowerFamily setFamilyStep={setFamilyStep} />
      {/* {familyStep && <BorrowerOccupation />} */}
    </div>
  );
};

export default Step4;
