import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { Controller, useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
import ListWithSearc from "../../Common/ListWithSearch/ListWithSearc";
const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    control,
    setValue,
  } = useForm();
  const BorrowerID = watch("borrower_id");
  const [{ data: depatmentList }, { data: BorrowId }, { data: paymentStatus }] =
    useQueries({
      queries: [
        {
          queryKey: ["department"],
          queryFn: () =>
            api.get("admin/lender-screener").then((res) => res.data),
          initialData: [],
        },
        {
          queryKey: ["borrowerscreener"],
          queryFn: () =>
            api.get("admin/get-borrower-screener").then((res) => res.data),
          initialData: [],
        },
        {
          queryKey: ["subDeta", BorrowerID],
          queryFn: () =>
            api
              .get(`admin/get-subvention-payment-status/${BorrowerID}`)
              .then((res) => res.data),
          initialData: [],
          enabled: !!BorrowerID,
        },
      ],
    });
  useEffect(() => {
    if (defaultValue) {
      setValue("utr_number", defaultValue?.utr_number);
      setValue("disbursement_amount", defaultValue.disbursement_amount);
      setValue("disbursement_date", defaultValue.disbursement_date);
      setValue("loan_id", defaultValue?.loan_id);
      setValue("bank_name", defaultValue?.bank_name);
      // setValue("borrower_id", defaultValue.borrower_id);
      setValue(
        "borrower_id",
        defaultValue.borrower_id ? Number(defaultValue.borrower_id) : null
      );
      setValue(
        "lender_id",
        defaultValue.lender_id ? Number(defaultValue.lender_id) : null
      );
      // setValue("lender_id", defaultValue?.lender_id);
      setValue("transaction_id", defaultValue?.transaction_id);
    }
  }, [defaultValue, setValue, depatmentList, BorrowId]);

  const onSubmit = async (data) => {
    let payload;
    if (defaultValue) {
      payload = {
        utr_number: data?.utr_number,
        disbursement_amount: data.disbursement_amount,
        loan_id: data.loan_id,
        disbursement_date: data.disbursement_date,
        borrower_id: data?.borrower_id,
        lender_id: data?.lender_id,
        transaction_id: data?.transaction_id,
        bank_name: data?.bank_name,
      };
    } else {
      payload = {
        utr_number: data?.utr_number,
        disbursement_amount: data.disbursement_amount,
        loan_id: data.loan_id,
        disbursement_date: data.disbursement_date,
        transaction_id: data?.transaction_id,
        borrower_id: data?.borrower_id,
        lender_id: data?.lender_id,
        bank_name: data?.bank_name,
      };
    }
    // console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };
  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `admin/update-disbursement-details/${defaultValue.id}`
      : "admin/store-disbursement-details",
    method: defaultValue ? "post" : "post",
    // method: defaultValue ? "put" : "post",
    invalidateKey: "userlist",
    successCallback: () => {
      reset();
      addHandle();
    },
    // setErr: setErr,
  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "520px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue
            ? "Edit Disbursement Details"
            : "Add Disbursement Details"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.searorinpzrssw}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Borrower ID<span className={styles.requ}>*</span>
            </lebel>

            <Controller
              control={control}
              rules={{ required: "This Field is Missing" }}
              render={({ field: { onChange, value } }) => (
                <ListWithSearc
                  options={BorrowId?.data || []}
                  getOptionLabel={(val) => val.borrower_id || ""}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={option.id}
                    >{`${option.borrower_id}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    BorrowId?.data?.find((employee) => employee.id === value) ||
                    null
                  }
                />
              )}
              name={`borrower_id`}
            />
            {errors.borrower_id && (
              <span className={styles.error}>"This Field is Missing.</span>
            )}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              UTR Number<span className={styles.requ}>*</span>
            </lebel>
            <input
              {...register("utr_number", { required: true })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
              onChange={(e) => (e.target.value = e.target.value.toUpperCase())}
            />
            {errors.utr_number && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Disbursement Amount<span className={styles.requ}>*</span>
            </lebel>
            <input
              {...register("disbursement_amount", {
                required: true,
              })}
              type="number"
              step="0.01"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />

            {errors.disbursement_amount && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Disbursement Date<span className={styles.requ}>*</span>
            </lebel>
            <input
              {...register("disbursement_date", { required: true })}
              type="date"
              style={{ width: "225px", color: "#494646" }}
              className={styles.mdlinput}
            />
            {errors.disbursement_date && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Loan ID<span className={styles.requ}>*</span>
            </lebel>
            <input
              {...register("loan_id", {
                required: true,
              })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />

            {errors.loan_id && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>

          <div className={styles.searorinpzrssw}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Lender<span className={styles.requ}>*</span>
            </lebel>
            {/* <select
              style={{ width: "235px" }}
              {...register("lender_id", { required: true })}
              className={styles.mdselecttt}
            >
              <option value="" className={styles.optionnsel}>
                --Select Lender--
              </option>
              {depatmentList?.data?.map((val) => (
                <option key={val.id} value={val.id}>
                  {val.name}
                </option>
              ))}
            </select> */}
            <Controller
              control={control}
              rules={{ required: "This Field is Missing" }}
              render={({ field: { onChange, value } }) => (
                <ListWithSearch
                  options={depatmentList?.data || []}
                  getOptionLabel={(val) => val.name || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{`${option.name}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    depatmentList?.data?.find(
                      (employee) => employee.id === value
                    ) || null
                  }
                />
              )}
              name={`lender_id`}
            />

            {errors.lender_id && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Transaction ID<span className={styles.requ}>*</span>
            </lebel>
            <input
              {...register("transaction_id", { required: true })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.transaction_id && (
              <span className={styles.error}>"This Field is Missing.</span>
            )}
          </div>
          <div className={styles.modalform}>
            <lebel
              style={{
                marginBottom: "3px",
                color: "#494646",
                fontSize: "13px",
              }}
            >
              Bank Name<span className={styles.requ}>*</span>
            </lebel>
            <input
              {...register("bank_name", { required: true })}
              type="text"
              style={{ width: "225px" }}
              className={styles.mdlinput}
            />
            {errors.bank_name && (
              <span className={styles.error}>"This Field is Missing.</span>
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        {BorrowerID && paymentStatus?.data?.payment_status === "UNPAID" && (
          <p
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "red",
              textAlign: "left",
            }}
          >
            The Borrower's Subvention Payment is Pending.
          </p>
        )}
        {paymentStatus?.data?.payment_status === "PAID" && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p></p>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                type="button"
                className={styles.closebtn}
                onClick={addHandle}
              >
                Close
              </button>
              <button type="submit" className={styles.Subbtn}>
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default AddModal;
