import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
import AdditionalElev from "./AdditionalElev";
import axios from "axios";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { AuthContext } from "../../../context/AuthContext";
import { useToast } from "../../../context/ToastProvder";
import { LoanContext } from "../../../context/LoanContext";
import { MdLocationPin } from "react-icons/md";
const StepElev = () => {
  const { borrId } = useContext(LoanContext);
  const [intitialStep, setInitialStep] = useState(false);
  const { showToast } = useToast();
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [proId, setProductId] = useState(null);
  const [shopAddr, setShopAdd] = useState(null);
  const HandleShow = () => {
    setShow(true);
  };
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [{ data: shopList }] = useQueries({
    queries: [
      {
        queryKey: ["shopl"],
        queryFn: () =>
          api
            .get(`admin/get-dealer-shops-fi/${borrId}`)
            .then((res) => res.data),
        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //  console.log("Loan Status:", response.data);

          const { product_id, step_details } = response?.data;
          const { step } = step_details;

          // setProductId(product_id);

          if (step === 16) {
            setInitialStep(true);
          }
        })
        .catch((error) => {
          //  console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("borrower_id", borrId);
    formData.append("name", data.name);
    formData.append("mobile", data.mobile);
    formData.append("dealer_shop_id", data.dealer_shop_id);

    try {
      const apiEndpoint = `${baseURL}admin/store-field-verification-details`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      //   console.log("Form submitted successfully:", response.data);
      setInitialStep(true);
    } catch (error) {
      //  console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;
      //  console.log("message", message);
      showToast(message, "error");
    }
  };
  console.log("shopAddr", shopAddr);
  return (
    <div>
      {!intitialStep && (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.tablco}>
          <p className={classes.applheader}>FIELD INSPECTION REQUEST</p>
          <div className={classes.totalcheckconnn}>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Name<span className={classes.requ}>*</span>
              </label>
              <input
                // placeholder="Enter Name"
                {...register("name", {
                  required: true,
                  validate: (value) =>
                    /^[A-Za-z\s]+$/.test(value) ||
                    "Only letters and spaces are allowed.",
                })}
                type="text"
                className={classes.inputstst}
              />

              {errors.name && (
                <span className={classes.error}>
                  {errors.name.message || "This Field is Missing."}
                </span>
              )}
            </div>

            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Mobile Number<span className={classes.requ}>*</span>
              </label>
              <input
                // placeholder="Enter Mobile Number"
                {...register("mobile", { required: true })}
                type="number"
                className={classes.inputstst}
              />

              {errors.mobile && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Shop<span className={classes.requ}>*</span>
              </label>

              <select
                {...register("dealer_shop_id", { required: true })}
                className={classes.inputstst}
                style={{ height: "29px" }}
                onChange={(e) => {
                  const selectedShop = shopList?.data?.find(
                    (shop) => shop.id === Number(e.target.value)
                  );
                  setShopAdd(selectedShop ? selectedShop.address : null);
                }}
              >
                <option value="">--Select Shop--</option>
                {shopList?.data?.map((row) => (
                  <option value={row.id}>{row?.shop_name}</option>
                ))}
              </select>
              {errors.gender && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          {shopAddr && (
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: "11px",
                fontWeight: "bold",
                marginTop: "5px",
              }}
            >
              <MdLocationPin
                size={14}
                style={{ position: "relative", top: "3px" }}
              />
              Shop Address:{shopAddr}
            </p>
          )}
          <div
            className={classes.buttonasc}
            style={{ marginTop: "10px", textAlign: "left" }}
          >
            {/* {!defaultValue && ( */}
            <button
              type="submit"
              className={classes.Subbtn}
              style={{ width: "90px" }}
            >
              Submit
            </button>
          </div>
        </form>
      )}
      {intitialStep && <AdditionalElev />}
    </div>
  );
};

export default StepElev;
