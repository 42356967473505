import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

import { Backdrop } from "@mui/material";
import AdharVer from "./AdharVer";
import api from "../../Common/api";
import { useToast } from "../../../context/ToastProvder";
import { useQueries } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import { AuthContext } from "../../../context/AuthContext";
import ListWithSearc from "../../Common/ListWithSearch/ListWithSearc";
const ThirdStep = () => {
  const {
    userD,
    handleNext,
    borrId,
    dataFromAdhar,
    setDataFromAdhar,
    userId,
    setUserD,
  } = useContext(LoanContext);
  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [adharStatus, setAdharStatus] = useState(false);
  const [panStatus, setPanStatus] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isPanVerifying, setIsPanVerifying] = useState(false);
  const [dlStatus, setDlStatus] = useState(false);

  const [aadharResponse, setAadharResponse] = useState(null);
  const [panResponse, setPanResponse] = useState(null);
  const [initialU, setInitialU] = useState(null);
  const [aggregator, setAggregator] = useState(null);

  const {
    register,

    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const aadharNum = watch("aadhar");
  const panNum = watch("pan");
  const dlNum = watch("dl_no");
  const mStatus = watch("marital_status");
  const [credit, setCredit] = useState(false);
  const { token, baseURL, baseDoc, ver } = useContext(AuthContext);
  const concentAccepted = watch("concent_accepted");
  const Fip = watch("fip_id");
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const openHandle = () => {
    setOpen((val) => !val);
  };
  const [
    { data: genderList },
    { data: marrigeList },
    { data: concentData },
    // { data: FipData },
  ] = useQueries({
    queries: [
      {
        queryKey: ["genderl"],
        queryFn: () => api.get("get-genders").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["marry"],
        queryFn: () => api.get("get-marital-status").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["conce"],
        queryFn: () =>
          api.get("get-bureau-consent-data").then((res) => res.data),
        initialData: [],
      },
      // {
      //   queryKey: ["fipda"],
      //   queryFn: () => api.get("admin/get-fips").then((res) => res.data),
      //   initialData: [],
      //   enabled: !!credit && !!concentAccepted,
      // },
    ],
  });

  useEffect(() => {
    if (!userD && borrId) {
      const fetchBorrowerDetails = async () => {
        try {
          const response = await api.get(
            `admin/get-borrower-registration-details/${borrId}`
          );
          setInitialU(response.data.data);
        } catch (error) {
          // console.error("Error fetching borrower details:", error);
        }
      };

      fetchBorrowerDetails();
    }
  }, [userD, borrId]);
  useEffect(() => {
    if (!userD && borrId) {
      const fetchBorrowerDetails = async () => {
        try {
          const response = await api.post("admin/get-user-address-details", {
            borrower_id: borrId,
            user_type: "BORROWER",
          });
          // console.log(response.data.data);
          setUserD(response.data.data);
        } catch (error) {
          // console.error("Error fetching borrower details:", error);
        }
      };

      fetchBorrowerDetails();
    }
  }, [userD, borrId]);
  useEffect(() => {
    if (Fip) {
      const fetchaccountaggregator = async () => {
        try {
          const response = await api.post(
            "admin/initiate-account-aggregator-request",
            {
              user_id: userD?.id || userId,
              mobile: userD?.mobile || initialU?.mobile,
              pan_no: panNum,
              fip_id: Fip,
              borrower_id: borrId,
              applicant_type: "BORROWER",
            }
          );
          // console.log(response.data.data);
          setAggregator(response.data.data);
        } catch (error) {
          // console.error("Error fetching borrower details:", error);
        }
      };

      fetchaccountaggregator();
    }
  }, [Fip]);

  const handleDownloadForm = async () => {
    const payload = {
      borrower_id: borrId,
      applicant_type: "BORROWER",
    };

    try {
      const queryString = new URLSearchParams(payload).toString();
      const response = await api.get(
        `admin/get-name-declaration?${queryString}`
      );
      if (response.data.status === "success" && response.data.file_url) {
        const downloadUrl = `${baseDoc}${response.data.file_url}`;
        //  console.log("downloadUrl", downloadUrl);

        const fileResponse = await fetch(downloadUrl);
        const blob = await fileResponse.blob();

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "name_declaration_form.pdf");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      } else {
        showToast("Failed to retrieve the file URL", "error");
      }
    } catch (error) {
      //   console.error("Error downloading form:", error);
      showToast("Failed to download the form", "error");
    }
  };

  useEffect(() => {
    if (adharStatus) {
      setAdharStatus(false);
      setPanStatus(false);
      setAggregator(null);
    }
  }, [aadharNum]);
  useEffect(() => {
    if (panStatus) {
      setPanStatus(false);
      setAggregator(null);
    }
  }, [panNum]);

  useEffect(() => {
    if (panStatus && adharStatus && concentAccepted) {
      const fetchOtherDetails = async () => {
        const gender =
          dataFromAdhar.gender === "M"
            ? "Male"
            : dataFromAdhar.gender === "F"
            ? "Female"
            : dataFromAdhar.gender;
        const [day, month, year] = dataFromAdhar?.dob?.split("-");
        const formattedDOB = `${year}-${month}-${day}`;

        const payload = {
          borrower_id: borrId,
          applicant_type: "BORROWER",
          user_id: userD?.id || userId,
          mobile: userD?.mobile || initialU?.mobile,
          pan: panNum,
          name: panResponse?.name,
          pin: dataFromAdhar?.full_address?.pc,
          dob: formattedDOB,
        };
        try {
          const response = await api.post(
            `${ver}admin-get-credit-score-details`,
            // `${ver}get-credit-score-details`,
            // "v2/get-credit-score-details",
            payload
          );

          //      console.log("credit score response", response.data);
          if (response?.data?.status === "success") {
            setCredit(true);
          } else {
            setCredit(false);
          }
        } catch (error) {
          //    console.error("Error fetching borrower details:", error);
        }
      };

      fetchOtherDetails();
    }
  }, [
    userD,
    borrId,
    adharStatus,
    panStatus,
    panResponse,
    aadharResponse,
    concentAccepted,
  ]);
  useEffect(() => {
    if (dataFromAdhar) {
      setValue("full_name", dataFromAdhar.name);
      setValue("father_name", dataFromAdhar.father_name);
      setValue("husband_name", dataFromAdhar.husband_name);
      const gender =
        dataFromAdhar.gender === "M"
          ? "Male"
          : dataFromAdhar.gender === "F"
          ? "Female"
          : dataFromAdhar.gender;
      setValue("gender", gender);
      const [day, month, year] = dataFromAdhar?.dob?.split("-");
      const formattedDOB = `${year}-${month}-${day}`;
      setValue("dob", formattedDOB);
    }
  }, [dataFromAdhar, setValue]);
  const checkAadharVerification = async () => {
    if (aadharNum?.length !== 12) {
      showToast("Aadhar number must be exactly 12 digits", "error"); // Show toast for invalid length
      return;
    }

    try {
      setIsVerifying(true);
      //  const payload = {
      //    user_id: userD?.id || userId,
      //    borrower_id: borrId,
      //    applicant_type: "GUARANTOR",
      //    aadhar: aadharNum,
      //  };

      const checkResponse = await api.get(
        `admin/check-application-with-aadhar/${aadharNum}`
      );

      if (checkResponse.data.status === "success") {
        // If the check is successful, proceed with the verification
        await handleAadharVerification();
      }
    } catch (error) {
      const { message, errors } = error.response?.data || {};
      showToast(message || "Aadhar verification check failed", "error");
    } finally {
      setIsVerifying(false); // Set loading state back to false
    }
  };
  const handleAadharVerification = async () => {
    // if (aadharNum?.length !== 12) {
    //   showToast("Aadhar number must be exactly 12 digits", "error"); // Show toast for invalid length
    //   return;
    // }

    try {
      setIsVerifying(true);
      const payload = {
        user_id: userD?.id || userId,
        borrower_id: borrId,
        applicant_type: "BORROWER",
        aadhar: aadharNum,
      };

      const response = await api.post(
        `${ver}admin-verify-aadhar`,

        payload
      );

      setAadharResponse(response?.data?.data);

      addHandle();
      //   console.log("Aadhar verification successful:", response.data);
    } catch (error) {
      const { message, errors } = error.response.data;
      console.log("message", message);
      showToast(message, "error");
    } finally {
      setIsVerifying(false); // Set loading state back to false
    }
  };
  const handleDLVerification = async () => {
    const [day, month, year] = dataFromAdhar?.dob?.split("-");
    const formattedDOB = `${year}-${month}-${day}`;

    try {
      const payload = {
        user_id: userD?.id || userId,
        borrower_id: borrId,
        dob: formattedDOB,
        dl_number: dlNum,
      };

      const response = await api.post(
        `${ver}admin-verify-driving-licence`,

        payload
      );
      setAadharResponse(response.data.data);
      showToast("Driving licence Verification successful", "success");
      setDlStatus(true);
      //   console.log("Driving licence verification successful:", response.data);
    } catch (error) {
      const { message, errors } = error.response.data;
      //   console.log("message", message);
      showToast(message, "error");
    }
  };
  const handlePanVerification = async () => {
    if (panNum?.length !== 10) {
      showToast("PAN number must be exactly 12 digits", "error");
      return;
    }

    try {
      setIsPanVerifying(true);
      const payload = {
        user_id: userD?.id || userId,
        borrower_id: borrId,
        applicant_type: "BORROWER",
        pan: panNum,
        name: dataFromAdhar?.name,
      };

      const response = await api.post(
        //  "v2/verify-pan-details",
        `${ver}admin-verify-pan-details`,
        // `${ver}verify-pan-details`,
        payload
      );
      setPanResponse(response.data.data);
      showToast("PAN Verification successful", "success");
      setPanStatus(true);
      //   console.log("Pan verification successful:", response.data);
    } catch (error) {
      const { message, errors } = error.response.data;
      //   console.log("message", message);
      showToast(message, "error");
    } finally {
      setIsPanVerifying(false); // Set loading state back to false
    }
  };
  const onSubmit = async (data) => {
    if (!data.concent_accepted) {
      showToast("Please accept the consent to proceed.", "error");
      return; // Prevent form submission if consent is not accepted
    }

    const formData = new FormData();
    formData.append("borrower_id", borrId);
    formData.append("aadhar_number", data.aadhar);
    formData.append("pan_number", data.pan);
    formData.append("full_name", data.full_name);
    formData.append("dob", data.dob);
    formData.append("gender", data.gender);
    formData.append("mobile_number", userD?.mobile || initialU?.mobile);
    formData.append(
      "alternative_mobile_number",
      data.alternative_mobile_number
    );
    formData.append("email", userD?.email || initialU?.email);
    formData.append("marital_status", data.marital_status);
    formData.append("husband_name", data.husband_name || "");
    formData.append("father_name", data.father_name);
    formData.append("is_aadhar_verified", adharStatus ? "Yes" : "No");
    formData.append("is_pan_verified", panStatus ? "Yes" : "No");
    formData.append("is_licence_verified", dlStatus ? "Yes" : "No");
    formData.append("dl_no", data.dl_no);
    formData.append("consent_acceptance", data.concent_accepted);
    const fileFields = ["name_declaration"];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    try {
      const apiEndpoint = `${baseURL}admin/store-borrower-details`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      // console.log("Form submitted successfully:", response.data);
      handleNext();
    } catch (error) {
      //   console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;

      showToast(message, "error");
    }
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setValue("concent_accepted", "Yes");
    } else {
      setValue("concent_accepted", "No");
    }
  };

  return (
    <div className={classes.tablco}>
      {/* start from here  */}
      <div
        style={{
          marginTop: "10px",
          borderTop: "1px solid #ddd",
          padding: "15px 0",
        }}
      >
        <p className={classes.applheader}>BORROWER DETAILS :</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.totalcheckconnn}>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Aadhar Number
                <span className={classes.requ}>*</span>
              </label>
              <div style={{ display: "flex", width: "100%" }}>
                <input
                  // placeholder="Enter Name"
                  {...register("aadhar", { required: true })}
                  type="number"
                  style={{ borderRadius: " 5px 0 0 5px ", width: "300px" }}
                  className={classes.inputstst}
                />
                <button
                  type="button"
                  style={{
                    background: adharStatus ? "#45cb85" : "#fa6548",
                    border: "none",
                    color: "#fff",
                    borderRadius: "  0 5px 5px 0  ",
                    fontSize: "11px",
                  }}
                  onClick={checkAadharVerification}
                  disabled={isVerifying}
                >
                  {isVerifying
                    ? "Verifying..."
                    : adharStatus
                    ? "Verified"
                    : "Verify"}
                </button>
              </div>
            </div>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                PAN Number
                <span className={classes.requ}>*</span>
              </label>
              <div style={{ display: "flex", width: "100%" }}>
                <input
                  // placeholder="Enter Name"
                  {...register("pan", { required: true })}
                  type="text"
                  style={{ borderRadius: " 5px 0 0 5px ", width: "300px" }}
                  className={classes.inputstst}
                />
                <button
                  type="button"
                  disabled={isPanVerifying}
                  onClick={handlePanVerification}
                  style={{
                    background: panStatus ? "#45cb85" : "#fa6548",
                    border: "none",
                    color: "#fff",
                    borderRadius: "  0 5px 5px 0  ",
                    fontSize: "11px",
                  }}
                >
                  {panStatus ? "Verified" : "Verify"}
                </button>
              </div>
            </div>
          </div>
          {adharStatus && panStatus && (
            <div style={{ display: "flex", margin: "8px 0" }}>
              <input
                type="checkbox"
                // checked={concentAccepted === "Yes"}
                onChange={handleCheckboxChange}
                {...register("concent_accepted")}
              />

              <p
                style={{
                  color: "#212529",
                  fontSize: "11px",
                  position: "relative",
                  top: "2.5px",
                  textAlign: "left",
                }}
              >
                {concentData?.data}
              </p>
            </div>
          )}
          {/* start here */}
          {adharStatus && panStatus && credit && concentAccepted && (
            <div
              className={classes.totalcheckconnn}
              style={{ marginTop: "15px" }}
            >
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Full Name<span className={classes.requ}>*</span>
                </label>
                <input
                  // placeholder="Enter Name"
                  {...register("full_name", { required: true })}
                  type="text"
                  disabled
                  className={classes.inputstst}
                />

                {errors.full_name && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  DOB<span className={classes.requ}>*</span>
                </label>
                <input
                  // placeholder="Enter Name"
                  {...register("dob", { required: true })}
                  type="date"
                  disabled
                  className={classes.inputstst}
                />

                {errors.dob && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Alternative Mobile Number
                </label>
                <input
                  // placeholder="Enter Name"
                  {...register("alternative_mobile_number")}
                  type="number"
                  className={classes.inputstst}
                />

                {errors.alternative_mobile_number && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>{" "}
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Driving Licence Number
                </label>
                <div style={{ display: "flex", width: "100%" }}>
                  <input
                    {...register("dl_no")}
                    type="text"
                    style={{
                      borderRadius: " 5px 0 0 5px ",
                      width: "300px",
                    }}
                    className={classes.inputstst}
                  />
                  <button
                    type="button"
                    style={{
                      background: dlStatus ? "#45cb85" : "#fa6548",
                      border: "none",
                      color: "#fff",
                      borderRadius: "  0 5px 5px 0  ",
                      fontSize: "11px",
                    }}
                    onClick={handleDLVerification}
                  >
                    {dlStatus ? "Verified" : "Verify"}
                  </button>
                </div>
                {errors.dl_no && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Gender<span className={classes.requ}>*</span>
                </label>

                <select
                  {...register("gender", { required: true })}
                  className={classes.inputstst}
                  disabled
                  style={{ height: "29px" }}
                >
                  <option value="">--Select Gender--</option>
                  {genderList?.data?.map((row) => (
                    <option value={row}>{row}</option>
                  ))}
                </select>
                {errors.gender && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Martial Status<span className={classes.requ}>*</span>
                </label>

                <select
                  {...register("marital_status", { required: true })}
                  className={classes.inputstst}
                  style={{ height: "29px" }}
                >
                  <option value="">--Select Martial Status--</option>
                  {marrigeList?.data?.map((row) => (
                    <option value={row}>{row}</option>
                  ))}
                </select>
                {errors.marital_status && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              {/* <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Fip
                 
                </label>

                <Controller
                  control={control}
                  
                  render={({ field: { onChange, value } }) => (
                    <ListWithSearc
                      options={
                        FipData?.data?.fip?.filter((row) => row.enabled) || []
                      }
                      getOptionLabel={(val) => val.name || ""}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>{`${option.name}`}</li>
                      )}
                      className={classes.listSrchzddfsdf}
                      onChange={(event, selectedValue) => {
                        onChange(selectedValue ? selectedValue.id : null);
                      }}
                      value={
                        FipData?.data?.fip?.find((row) => row.id === value) ||
                        null
                      }
                    />
                  )}
                  name="fip_id"
                />
                {errors.fip_id && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div> */}
              {mStatus === "Married" && (
                <div className={classes.searorinp}>
                  <label
                    style={{
                      fontSize: "12px",
                      color: "#212529",
                      fontWeight: "bold",
                    }}
                  >
                    Husband / Wife Name
                    <span className={classes.requ}>*</span>
                  </label>

                  <input
                    {...register("husband_name", {
                      required: true,
                      validate: (value) =>
                        /^[A-Za-z\s]+$/.test(value) ||
                        "Only letters and spaces are allowed.",
                    })}
                    type="text"
                    disabled={Boolean(dataFromAdhar?.husband_name)}
                    className={classes.inputstst}
                  />
                  {errors.husband_name && (
                    <span className={classes.error}>
                      {errors.husband_name.message || "This Field is Missing."}
                    </span>
                  )}
                </div>
              )}
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Father's Name<span className={classes.requ}>*</span>
                </label>
                <input
                  {...register("father_name", {
                    required: true,
                    validate: (value) =>
                      /^[A-Za-z\s]+$/.test(value) ||
                      "Only letters and spaces are allowed.",
                  })}
                  type="text"
                  disabled={Boolean(dataFromAdhar?.father_name)}
                  className={classes.inputstst}
                />

                {errors.father_name && (
                  <span className={classes.error}>
                    {errors.father_name.message || "This Field is Missing."}
                  </span>
                )}
              </div>
              {panResponse?.name_match_score < 70 && (
                <div className={classes.searorinp}>
                  <label
                    style={{
                      fontSize: "12px",
                      color: "#212529",
                      fontWeight: "bold",
                    }}
                  >
                    Name Decleration<span className={classes.requ}>*</span>
                  </label>
                  <div style={{ display: "flex", width: "100%" }}>
                    <input
                      {...register("name_declaration", { required: true })}
                      type="file"
                      className={classes.uploadbxscff}
                    />
                  </div>
                  {errors.name_declaration && (
                    <span className={classes.error}>
                      This Field is Missing.
                    </span>
                  )}
                </div>
              )}
              {aggregator && (
                <div
                  className={classes.buttonasc}
                  style={{ marginTop: "10px", textAlign: "left" }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      if (aggregator?.redirectionUrl) {
                        window.open(aggregator.redirectionUrl, "_blank");
                      }
                    }}
                    className={classes.Subbtn}
                    style={{ width: "100px" }}
                  >
                    Open Link
                  </button>
                </div>
              )}
              {panResponse?.name_match_score < 70 && (
                <button
                  className={classes.Subbtn}
                  style={{ height: "29px", marginTop: "15px" }}
                  type="button"
                  onClick={handleDownloadForm}
                >
                  Download Name Decleration Form
                </button>
              )}
            </div>
          )}

          <div
            className={classes.buttonasc}
            style={{ marginTop: "10px", textAlign: "left" }}
          >
            {/* {!defaultValue && ( */}
          </div>
          {adharStatus && panStatus && credit && concentAccepted && (
            <div
              className={classes.buttonasc}
              style={{ marginTop: "10px", textAlign: "left" }}
            >
              {/* {!defaultValue && ( */}
              <button
                type="submit"
                className={classes.Subbtn}
                style={{ width: "80px" }}
              >
                Submit
              </button>
              {/* )} */}
            </div>
          )}
        </form>
      </div>
      {/* end Here  */}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <AdharVer
          addHandle={addHandle}
          setAdharStatus={setAdharStatus}
          borrId={borrId}
          userD={userD}
          aadharResponse={aadharResponse}
          aadharNum={aadharNum}
          setDataFromAdhar={setDataFromAdhar}
          type="BORROWER"
        />
      </Backdrop>
    </div>
  );
};

export default ThirdStep;
