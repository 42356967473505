import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import CustomActInBox from "../../Common/Custom Confirm/CustomActInBox";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const deleteHandler = (data) => {
    setSelectedItem(data);
    handleClickOpen();
    console.log(data);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const deleteConfirmHandler = () => {
    console.log("selectedData", selectedItem);
    updateDelMutation.mutate(selectedItem);
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const updateDelMutation = useCustomMutation({
    url: `admin/update-state-status/${selectedItem?.id}`,
    method: "post",
    invalidateKey: "langList",
    // setErr: setErr,
    successCallback: () => {},
  });
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {" "}
      {open && (
        <CustomActInBox
          data={selectedItem}
          onCancel={handleClose}
          onConfirm={deleteConfirmHandler}
          message={`Are you sure you want to ${
            selectedItem.status === "active" ? "deactivate" : "activate"
          } ${selectedItem.state}`}
          open={open}
        />
      )}
      <div className={styles.tabwrap}>
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>State Name</th>

                <th> Status</th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr key={row.id}>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.state}</td>
                  {perm?.includes("update") ? (
                    <td>
                      {row.status === "active" ? (
                        <button
                          // title="Inactivate Status"
                          type="button"
                          onClick={() => deleteHandler(row)}
                          style={{ minWidth: "70px" }}
                          className={styles.accessbtn}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          // title="Activate Status"
                          type="button"
                          onClick={() => deleteHandler(row)}
                          style={{ minWidth: "70px" }}
                          className={styles.accessbtnnegativ}
                        >
                          Inactive
                        </button>
                      )}
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>
                    {" "}
                    {perm?.includes("update") && (
                      <button
                        title="Edit"
                        type="button"
                        onClick={() => handleEditRow(row)}
                        className={styles.editbtn}
                      >
                        <AiFillEdit
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    {/* {perm?.includes("delete") && (
                      <button className={styles.dltbtn} type="button">
                        <MdDelete
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
      </div>
    </div>
  );
};

export default Table;
