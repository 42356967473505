import React, { useState } from "react";
import styles from "../Lenders/Table.module.css";
import { FaEye, FaSort } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import AddModal from "./AddModal";
import CustomActInBox from "../../Common/Custom Confirm/CustomActInBox";
import useCustomMutation from "../../CustomHook/useCustomMutation";
// import RemarkM from "./RemarkM";
// import RemarkView from "./RemarkView";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [re, setRe] = useState(false);
  const [type, setType] = useState(null);
  const [viewM, setViewM] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const addViewHandle = () => {
    setViewM((val) => !val);
  };
  const reHandle = () => {
    setRe((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const HandleApprove = (id, type) => {
    setRowToEdit(id);
    setType(type);

    setRe((val) => !val);
  };
  const HandleReject = (id, type) => {
    setRowToEdit(id);

    setRe((val) => !val);
    setType(type);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const HandleView = (id) => {
    setRowToEdit(id);

    setViewM((val) => !val);
  };
  const deleteConfirmHandler = () => {
    console.log("selectedData", selectedItem);
    updateDelMutation.mutate(selectedItem);
    handleClose();
  };
  const updateDelMutation = useCustomMutation({
    url: `admin/change-admin-user-status/${selectedItem?.id}`,
    method: "post",
    invalidateKey: "langList",
    // setErr: setErr,
    successCallback: () => {
      // reset();
      // addHandle();
    },
  });
  const deleteHandler = (data) => {
    setSelectedItem(data);
    handleClickOpen();
    console.log(data);
  };
  console.log("rowToEdit", rowToEdit);
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      {" "}
      {open && (
        <CustomActInBox
          data={selectedItem}
          onCancel={handleClose}
          onConfirm={deleteConfirmHandler}
          message={`Are you sure you want to ${
            selectedItem.is_active ? "deactivate" : "activate"
          } ${selectedItem.name}`}
          open={open}
        />
      )}
      <div className={styles.tabwrap}>
        {" "}
        {rowData && rowData?.length > 0 ? (
          <table>
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#12468a",
                backgroundImage:
                  "radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%)",
                zIndex: "5",
                borderBottom: "1px solid #ddd",
              }}
            >
              <tr>
                <th>#</th>
                <th>Product Type</th>
                <th>Min Loan Amount</th>
                <th>Max Loan Amount</th>
                <th>Min Loan Tenure</th>
                <th>Max Loan Tenure</th>

                <th>Created On</th>
                <th>Updated By</th>
                <th>Updated On</th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {rowData?.map((row, index) => (
                <tr>
                  <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                  <td>{row.product_type?.product_type}</td>
                  <td>{row.min_loan_amount}</td>
                  <td>{row.max_loan_amount}</td>
                  <td>{row.min_loan_tenure}</td>
                  <td>{row.max_loan_tenure}</td>
                  <td>{row.created_at}</td>
                  <td>{row.modified_by?.name}</td>
                  <td>{row.updated_at}</td>
                  {/* <td>{row.max_loan_amount}</td>
                  <td>{row.min_loan_tenure}</td>
                  <td>{row.max_loan_tenure}</td> */}

                  {/* {perm?.includes("update") ? (
                    <td style={{ maxWidth: "90px" }}>
                      {row?.is_active === true ? (
                        <button
                          type="button"
                          onClick={() => HandleApprove(row, "approve")}
                          style={{ minWidth: "70px" }}
                          className={styles.accessbtn}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => HandleReject(row, "reject")}
                          className={styles.accessbtnnegativ}
                          style={{ minWidth: "70px" }}
                        >
                          Inactive
                        </button>
                      )}
                    </td>
                  ) : (
                    <td></td>
                  )} */}
                  <td>
                    {" "}
                    {perm?.includes("update") && (
                      <button
                        title="Edit"
                        type="button"
                        onClick={() => handleEditRow(row)}
                        className={styles.editbtn}
                      >
                        <AiFillEdit
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    {row.remarks && (
                      <button
                        type="button"
                        title="Remark"
                        onClick={() => HandleView(row)}
                        className={styles.viewbtn}
                      >
                        <FaEye
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )}
                    {/* {perm?.includes("delete") && (
                      <button
                        title="Delete"
                        type="button"
                        className={styles.dltbtn}
                      >
                        <MdDelete
                          size={12}
                          style={{ position: "relative", top: "1px" }}
                        />
                      </button>
                    )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ textAlign: "left" }}>No Record Found</p>
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
        {/* <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={re}
          onClick={reHandle}
        >
          <RemarkM
            addHandle={reHandle}
            defaultValue={rowToEdit || ""}
            type={type || ""}
          />
        </Backdrop>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={viewM}
          onClick={addViewHandle}
        >
          <RemarkView
            addHandle={addViewHandle}
            defaultValue={rowToEdit || ""}
          />
        </Backdrop> */}
      </div>
    </div>
  );
};

export default Table;
