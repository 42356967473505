import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";

const RepayS = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();
  // useEffect(() => {
  //   if (defaultValue) {
  //     setValue("startDate", defaultValue.startDate);
  //     setValue("vpfAmount", defaultValue.amount);
  //   }
  // }, [defaultValue, setValue]);

  const onSubmit = async (data) => {
    let payload;
    if (defaultValue) {
      payload = {
        employeeId: defaultValue.empId,
        startDate: data.startDate,
        vpfAmount: data.vpfAmount,
        calculatedOn: "amount",
      };
      console.log("updatedData", payload);
      //  updateVpnMutation.mutate(payload);
    }
  };

  //  const updateVpnMutation = useCustomMutation({
  //    url: "mode/employee-vpf-setting/submitVpfSettingForm",
  //    invalidateKey: "vpfList",
  //    successCallback: () => {
  //      reset();
  //      addHandle();
  //    },
  //  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
      style={{ width: "700px" }}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Repayment Schedules</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.totalcheckconnn}
        style={{ padding: "15px", height: "350px", overflowY: "auto" }}
      >
        {defaultValue.length > 0 ? (
          defaultValue?.map((offer) => (
            <div key={offer.id} className={styles.applylonffer}>
              <p
                style={{ padding: "6px", fontSize: "13px" }}
                className={styles.applyluptxt}
              >
                Month {offer?.month}
              </p>
              <div className={styles.applylonffergg}>
                <div className={styles.divinffe}>
                  <p className={styles.txtfoofl}>Principal</p>
                  <p className={styles.txtfooflbtone}>₹{offer?.principal}</p>
                </div>
                <div className={styles.divinffe}>
                  <p className={styles.txtfoofl}>Interest</p>
                  <p className={styles.txtfooflbtone}>
                    <p className={styles.txtfooflbtone}>{offer?.interest}</p>
                  </p>
                </div>
              </div>
              <div className={styles.applylonffergg}>
                <div className={styles.divinffe}>
                  <p className={styles.txtfoofl}>EMI Amount</p>
                  <p className={styles.txtfooflbtone}>
                    ₹{offer?.total_payment}
                  </p>
                </div>
                <div className={styles.divinffe}>
                  <p className={styles.txtfoofl}>Principal Outstanding</p>
                  <p className={styles.txtfooflbtone}>
                    ₹{offer?.remaining_balance}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No repayment schedules available.</p>
        )}
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
        </div>
      </div>
    </form>
  );
};

export default RepayS;
