import React from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useToast } from "../../../context/ToastProvder";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import ListWithSearch from "../../Common/ListWithSearch/ListWithSearch";
import ListWithSearc from "../../Common/ListWithSearch/ListWithSearc";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch, control } = useForm();
  // const onSubmit = (data) => {
  //   console.log("searchForm submitted:", data);
  //   onSearch(data);
  // };
  // const [
  const [{ data: borrowerList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("admin/get-borrower-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });

  const { showToast } = useToast();
  const onSubmit = (data) => {
    const { start_date, end_date } = data;
    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }

    console.log("searchForm submitted:", data);
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.totalcheckconnn}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Borrower ID
            </label>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ListWithSearc
                  options={borrowerList?.data || []}
                  getOptionLabel={(val) => val.borrower_id || ""}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={option.id}
                    >{`${option.borrower_id}`}</li>
                  )}
                  className={styles.listSrch}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    borrowerList?.data?.find(
                      (employee) => employee.id === value
                    ) || null
                  }
                />
              )}
              name={`borrower_id`}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Request Start Date
            </label>
            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("request_start_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Request End Date
            </label>

            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("request_end_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Modification Start Date
            </label>

            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("modification_start_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Modification End Date
            </label>

            <input
              type="date"
              max={currentDate}
              className={styles.inpttsc}
              {...register("modification_end_date")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Disbursement Amount
            </label>
            <input
              type="number"
              step="0.01"
              className={styles.inpttsc}
              {...register("disbursement_amount")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Status
            </label>
            <select
              style={{ height: "30px" }}
              className={styles.inpttsc}
              {...register("status")}
            >
              <option value="">--Select Status--</option>
              <option value="PENDING">PENDING</option>
              <option value="REJECTED">REJECTED</option>
              <option value="APPROVED">APPROVED</option>
            </select>
          </div>
        </div>

        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
