import React from "react";
import { useForm } from "react-hook-form";
import styles from "../Borrower/Search.module.css";
import { useToast } from "../../../context/ToastProvder";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch } = useForm();
  const { showToast } = useToast();
  const onSubmit = (data) => {
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.oneinputads} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Contact Number
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("mobile")}
            />
          </div>
        </div>

        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button className={styles.searcchfrs} type="submit">
            Search
          </button>
          <button
            className={styles.clrrchfrs}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
