import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";
import Thankyou from "../../../icons/Thnaks.png";

import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
import CoAEsign from "./CoAEsign";
// import GuaEign from "./GuaEign";
const Step23 = () => {
  const [intitialStep, setInitialStep] = useState(false);
  const [showGen, setShowGen] = useState(true);
  const [stpp, setStpp] = useState(null);

  const [showRegenButton, setShowRegenButton] = useState(false);
  const [showGenG, setShowGenG] = useState(true);
  const [showRegenButtonG, setShowRegenButtonG] = useState(false);
  const {
    borrId,
    handleNext,
    setActiveStep,
    activeStep,
    bbData,
    setbbData,
    productId,
    setProductId,
  } = useContext(LoanContext);
  const { showToast } = useToast();

  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //  console.log("Loan Status:", response.data);

          const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setProductId(product_id);
          setStpp(step);
          if (step === 23) {
            // setInitialStep(true);
            setShowGen(false);
            setShowRegenButton(true);
          }
          //   if (step === 24) {
          //     setInitialStep(true);
          //     // setShowGen(false);
          //     // setShowRegenButton(true);
          //   }
          //   if (step === 25) {
          //     setInitialStep(true);
          //     setShowGenG(false);
          //     setShowRegenButtonG(true);
          //   }
        })
        .catch((error) => {
          //    console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  console.log("activeStep", activeStep);
  useEffect(() => {
    if (borrId) {
      const fetchBorrowerDetails = async () => {
        try {
          const response = await api.post("admin/get-borrower-details", {
            borrower_id: borrId,
          });

          const borrowerData = response?.data?.data;
          setbbData(borrowerData);

          if (borrowerData?.skip_co_applicant && borrowerData?.skip_guarantor) {
            //  handleNext();
            setActiveStep(24);
          }
          if (
            productId === "1" &&
            borrowerData?.co_applicant_step === "not_required" &&
            borrowerData?.guarantor_step === "not_required"
          ) {
            //   setActiveStep(14);
            setActiveStep(24);
          }
          if (
            borrowerData?.skip_co_applicant ||
            (productId === "1" &&
              borrowerData?.co_applicant_step === "not_required")
          ) {
            setInitialStep(23);
          }
        } catch (error) {
          //  console.error("Error fetching borrower details:", error);
        }
      };

      fetchBorrowerDetails();
    }
  }, [borrId, productId]);
  return (
    <div className={classes.tablco}>
      <CoAEsign
        showGen={showGen}
        setShowGen={setShowGen}
        showRegenButton={showRegenButton}
        setShowRegenButton={setShowRegenButton}
        setInitialStep={setInitialStep}
      />
    </div>
  );
};

export default Step23;
