import React, { useContext, useState } from "react";
import classes from "./Language.module.css";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import { Backdrop } from "@mui/material";
import RepayS from "./RepayS";

const Additional10 = () => {
  const { borrId, dealerId, setdealerId, handleNext } = useContext(LoanContext);
  const [add, setAdd] = useState(false);
  const [resD, setResD] = useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  const handleSub = (rowIndex) => {
    setdealerId(rowIndex);
    handleNext();
  };

  const [{ data: relationList }] = useQueries({
    queries: [
      {
        queryKey: ["rela", borrId],
        queryFn: async () => {
          const payload = { borrower_id: borrId };
          const res = await api.post("admin/run-rule-engine", payload);
          // const res = await api.get(`admin/run-rule-engine/${borrId}`);

          return res.data;
        },
        enabled: !!borrId,
        initialData: [],
      },
    ],
  });

  return (
    <div className={classes.totalcheckconnn}>
      {relationList?.offers?.map((offer) => (
        <div key={offer.id} className={classes.applylonffer}>
          <p
            style={{ padding: "6px", fontSize: "13px" }}
            className={classes.applyluptxt}
          >
            {offer?.lender}
          </p>
          <div className={classes.applylonffergg}>
            <div className={classes.divinffe}>
              <p className={classes.txtfoofl}>Loan Amount</p>
              <p className={classes.txtfooflbtone}>
                ₹{offer?.loan_details_break_down?.net_loan_amount}
              </p>
            </div>
            <div className={classes.divinffe}>
              <p className={classes.txtfoofl}>Rate of Interest</p>
              <p className={classes.txtfooflbtone}>
                {offer?.loan_details_break_down?.roi}%
              </p>
            </div>
          </div>
          <div className={classes.applylonffergg}>
            <div className={classes.divinffe}>
              <p className={classes.txtfoofl}>Terms</p>
              <p className={classes.txtfooflbtone}>
                {offer?.loan_details_break_down?.tenure}
              </p>
            </div>
            <div className={classes.divinffe}>
              <p className={classes.txtfoofl}>EMI per Month</p>
              <p className={classes.txtfooflbtone}>
                ₹{offer?.loan_details_break_down?.emi}
              </p>
            </div>
          </div>
          <button
            style={{ width: "96%", margin: "5px" }}
            type="button"
            onClick={() => handleEditRow(offer?.repayment_schedule)}
            className={classes.aboacptbtn}
          >
            Repayment Schedules
          </button>
          <button
            type="button"
            className={classes.acptbtn}
            onClick={() => handleSub(offer?.id)}
          >
            Accept
          </button>
        </div>
      ))}
      {rowToEdit && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <RepayS addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
      )}
    </div>
  );
};

export default Additional10;
