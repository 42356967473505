import React, { useContext, useState } from "react";
import classes from "./Language.module.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import { useToast } from "../../../context/ToastProvder";
import { LoanContext } from "../../../context/LoanContext";
const Additional13 = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [aaFDoc, setAaFDoc] = useState(null);
  const [aaBDoc, setAaBDoc] = useState(null);
  const [paDoc, setPaDoc] = useState(null);
  const { showToast } = useToast();
  const { borrId, userId, userD, handleNext } = useContext(LoanContext);
  const [aaF, setAaF] = useState(false);
  const [aaB, setAaB] = useState(false);
  const [pa, setPa] = useState(false);
  const { token, baseURL, ver } = useContext(AuthContext);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("borrower_id", borrId);
    formData.append("aadhar_front", aaFDoc);
    formData.append("aadhar_back", aaBDoc);
    formData.append("pan_card", paDoc);
    const fileFields = [
      // "aadhar_front",
      // "aadhar_back",
      // "pan_card",
      "photo",
      "electric_bill",
    ];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }

    try {
      const apiEndpoint = `${baseURL}admin/upload-coapplicant-document`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response?.data;

      showToast(message, status);
      handleNext();
      //  console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      const { message, errors } = error?.response?.data;
      console.log("message", message);
      showToast(message, "error");
    }
  };
  const handleAdharCardChange = async (event) => {
    const file = event.target.files[0];
    setAaFDoc(file);
    if (file) {
      const formData = new FormData();
      formData.append("user_id", userD?.id || userId);
      formData.append("borrower_id", borrId);
      formData.append("aadhar_image", file);
      formData.append("user_type", "COAPPLICANT");

      try {
        const apiEndpoint = `${baseURL}${ver}admin-aadhar-ocr-check`;
        const response = await axios.post(apiEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        setAaF(true);
        const { status, message } = response.data;
        showToast(message, status === "success" ? "success" : "error");
        //  console.log("PAN OCR check successful:", response.data);
      } catch (error) {
        //   console.error("Error in PAN OCR check:", error);
        const { message } = error.response.data;
        showToast(message, "error");
        setAaF(false);
      }
    }
  };
  const handleAdharCardBChange = async (event) => {
    const file = event.target.files[0];
    setAaBDoc(file);
    if (file) {
      const formData = new FormData();
      formData.append("user_id", userD?.id || userId);
      formData.append("borrower_id", borrId);
      formData.append("aadhar_image", file);
      formData.append("user_type", "COAPPLICANT");

      try {
        const apiEndpoint = `${baseURL}${ver}admin-aadhar-ocr-check`;
        const response = await axios.post(apiEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        setAaB(true);
        const { status, message } = response.data;
        showToast(message, status === "success" ? "success" : "error");
        //   console.log("PAN OCR check successful:", response.data);
      } catch (error) {
        //   console.error("Error in PAN OCR check:", error);
        const { message } = error.response.data;
        showToast(message, "error");
        setAaB(false);
      }
    }
  };
  const handlePanCardChange = async (event) => {
    const file = event.target.files[0];
    setPaDoc(file);
    if (file) {
      const formData = new FormData();
      formData.append("user_id", userD?.id || userId);
      formData.append("borrower_id", borrId);
      formData.append("pan_image", file);
      formData.append("user_type", "COAPPLICANT");

      try {
        const apiEndpoint = `${baseURL}${ver}admin-pan-ocr-check`;
        const response = await axios.post(apiEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        setPa(true);
        const { status, message } = response.data;
        showToast(message, status === "success" ? "success" : "error");
        //   console.log("PAN OCR check successful:", response.data);
      } catch (error) {
        //   console.error("Error in PAN OCR check:", error);
        const { message } = error.response.data;
        setPa(false);
        showToast(message, "error");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className={classes.applheader}>CO-APPLICANT DOCUMENTS</p>
      <div className={classes.totalcheckconnnforimgsec}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Image<span className={classes.requ}>*</span>
          </label>
          <input
            accept="image/jpeg, image/png,image/jpg"
            {...register("photo", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.photo && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Front<span className={classes.requ}>*</span>
          </label>
          <input
            accept="image/jpeg, image/png,image/jpg"
            {...register("aadhar_front", { required: true })}
            type="file"
            className={classes.uploadbx}
            onChange={handleAdharCardChange}
          />

          {errors.aadhar_front && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Back<span className={classes.requ}>*</span>
          </label>
          <input
            accept="image/jpeg, image/png,image/jpg"
            {...register("aadhar_back", { required: true })}
            type="file"
            className={classes.uploadbx}
            onChange={handleAdharCardBChange}
          />

          {errors.aadhar_back && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            PAN Card<span className={classes.requ}>*</span>
          </label>
          <input
            accept="image/jpeg, image/png,image/jpg"
            {...register("pan_card", { required: true })}
            type="file"
            className={classes.uploadbx}
            onChange={handlePanCardChange}
          />

          {errors.pan_card && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Electricity Bill
          </label>
          <input
            accept="image/jpeg, image/png,image/jpg  , application/pdf"
            {...register("electric_bill")}
            type="file"
            className={classes.uploadbx}
          />
        </div>
      </div>
      {aaB && aaF && pa && (
        <div
          className={classes.buttonasc}
          style={{ marginTop: "10px", textAlign: "left" }}
        >
          {/* {!defaultValue && ( */}
          <button
            type="submit"
            className={classes.Subbtn}
            style={{ width: "90px", marginTop: "10px" }}
          >
            Submit
          </button>
        </div>
      )}
    </form>
  );
};

export default Additional13;
