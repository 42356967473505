import React, { useContext, useEffect } from "react";
import styles from "../CreateDealer/BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";

import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
const DealerForm = ({ defaultValue, rowData, val }) => {
  const { user, token, setUser, setToken, baseURL } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const [formdata, setFormdata] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});
  const [PFRemovedId, setPFRemovedId] = useState([]);
  const [numForms, setNumForms] = useState(
    formdata?.authorizedDirectors?.length || 1
  );
  const [authorizedDirectors, setAuthorizedDirectors] = useState([
    {
      AuthorizedDirectorsName: "",
      AuthorizedDirectorsNumber: "",
      AuthorizedDirectorsEmail: "",
    },
  ]);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [err, setErr] = useState({});
  const [perm, setPerm] = useState([]);
  const navigate = useNavigate();
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();

  useEffect(() => {
    if (rowData) {
      setValue("shop_name", rowData?.shop_name);
      setValue("longitude", rowData?.longitude);
      setValue("latitude", rowData?.latitude);
      setValue("address", rowData?.address);
    }
  }, [rowData, setValue]);
  const onSubmit = async (data) => {
    console.log("Form data to submit:", data);
    const formData = new FormData();
    formData.append("shop_name", data.shop_name);
    {
      !rowData && formData.append("dealer_id", defaultValue?.id);
    }
    formData.append("longitude", data.longitude);
    formData.append("latitude", data.latitude);
    formData.append("address", data.address);

    const fileFields = ["lat_long_image"];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    try {
      const apiEndpoint = rowData
        ? `${baseURL}admin/update-dealer-shop/${rowData?.id}`
        : `${baseURL}admin/store-dealer-shop`;
      // const method = defaultValue ? "patch" : "post";
      const method = "post";
      // const method = defaultValue ? "put" : "post";
      // const response = await axios.post(apiEndpoint, formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const response = await axios({
        method: method,
        url: apiEndpoint,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.status) {
        const { status, message } = response.data;
        showToast(message, status);
        navigate("/address-details", {
          state: { val },
        });
        console.log("Form submitted successfully:", response.data);
      } else {
        console.error("Unexpected response format:", response);
        showToast("Unexpected response format", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        setErr(message);
        showToast(message, "error");
      } else {
        showToast("An error occurred while submitting the form", "error");
      }
    }
  };
  useEffect(() => {
    if (rowData?.lat_long_image) {
      register("lat_long_image");
    } else {
      register("lat_long_image", { required: true });
    }
  }, [rowData, register]);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Shop Name<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("shop_name", { required: true })}
                type="text"
                className={styles.inputstst}
              />
              {errors.shop_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Longitude<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("longitude", { required: true })}
                type="number"
                step="0.00000000001"
                className={styles.inputstst}
              />
              {errors.longitude && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Latitude<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("latitude", { required: true })}
                type="number"
                step="0.00000000001"
                className={styles.inputstst}
              />
              {errors.latitude && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Address<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("address", { required: true })}
                type="text"
                className={styles.inputstst}
              />
              {errors.address && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Longitude Latitude Image<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("lat_long_image")}
                accept="image/jpeg, image/png,image/jpg"
              />
              {errors.lat_long_image && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>

          {/* {renderFormFields()} */}

          <div className={styles.buttonasc}>
            {/* {!defaultValue && ( */}
            <button
              style={{
                position: "relative",
                bottom: "15px",
                margin: "0",
                marginTop: "10px",
              }}
              type="submit"
              className={styles.Subbtn}
            >
              Submit
            </button>
            {/* )} */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default DealerForm;
