import React, { useContext, useEffect } from "react";
import styles from "../CreateDealer/BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useNavigate } from "react-router-dom";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import ListWithSearc from "../../Common/ListWithSearch/ListWithSearc";
import { useToast } from "../../../context/ToastProvder";
import { AuthContext } from "../../../context/AuthContext";
import { Backdrop } from "@mui/material";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
const RaiseReq = ({ defaultValue }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bonusList: [],
    },
  });
  const [addOn, setAddOn] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const BorrowerID = watch("borrower_id");
  const bonusList = watch("bonusList") || [];
  // useEffect(() => {
  //   setAddOn(
  //     bonusList.map((item) => ({
  //       description: item?.description || "",
  //       amount: item?.amount || "",
  //     }))
  //   );
  // }, [bonusList]);

  const totalAmount = addOn.reduce((total, item) => {
    const amount = parseFloat(item?.amount) || 0;
    return total + amount;
  }, 0);
  const bonusListt = watch("bonusListt") || [];
  // useEffect(() => {
  //   setDeductions(
  //     bonusListt.map((item) => ({
  //       description: item?.description || "",
  //       amount: item?.amount || "",
  //     }))
  //   );
  // }, [bonusListt]);

  const totalAmountD = deductions.reduce((total, item) => {
    const amount = parseFloat(item?.amount) || 0;
    return total + amount;
  }, 0);

  console.log("totalAmountD", totalAmountD);
  console.log("deduction", deductions);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { token, baseURL } = useContext(AuthContext);
  const [formdata, setFormdata] = useState(null);
  const [reqq, setReqq] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedOptionD, setSelectedOptionD] = useState({});
  const [PFRemovedId, setPFRemovedId] = useState([]);
  const [PFRemovedIdD, setPFRemovedIdD] = useState([]);

  const [numForms, setNumForms] = useState(
    formdata?.authorizedDirectors?.length || 1
  );
  const [numFormsD, setNumFormsD] = useState(
    formdata?.authorizedDirectorss?.length || 1
  );
  const [{ data: borrowerList }, { data: dealerDet }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("admin/get-borrower-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["subDeta", BorrowerID],
        queryFn: () =>
          api

            .get(`admin/get-borrower-loan-scheme/${BorrowerID}`)
            .then((res) => res.data),
        initialData: [],
        enabled: !!BorrowerID,
      },
    ],
  });
  // useEffect(() => {
  //   if (dealerDet && !defaultValue) {
  //     setValue("subvention_amount", dealerDet?.data?.subvention_amount);
  //   }
  // }, [setValue, dealerDet]);
  const handleAddForm = () => {
    setNumForms((prevNumForms) => {
      const newNumForms = prevNumForms + 1;

      // Update selectedOption
      setSelectedOption((prevState) => ({
        ...prevState,
        [newNumForms - 1]: "",
      }));

      // Update addOn state
      setAddOn((prevAddOn) => [
        ...prevAddOn,
        { description: "", amount: "" }, // Add a new empty object for the new form
      ]);

      return newNumForms;
    });
  };
  const handleAddFormD = () => {
    setNumFormsD((prevNumForms) => {
      const newNumFormsD = prevNumForms + 1;

      // Update selectedOption
      setSelectedOptionD((prevState) => ({
        ...prevState,
        [newNumFormsD - 1]: "",
      }));

      // Update addOn state
      setDeductions((prevAddOn) => [
        ...prevAddOn,
        { description: "", amount: "" }, // Add a new empty object for the new form
      ]);

      return newNumFormsD;
    });
  };

  const handleMinusForm = (index, id) => {
    // Remove the corresponding item from addOn state
    setAddOn((prevAddOn) => prevAddOn.filter((_, i) => i !== index));

    // Decrease numForms but ensure at least 1 form remains
    setNumForms((prevNumForms) => Math.max(prevNumForms - 1, 1));

    // Store removed ID if available
    if (id) {
      setPFRemovedId((prevIds) => [...prevIds, id]);
    }
  };
  const handleMinusFormD = (index, id) => {
    // Remove the corresponding item from addOn state
    setDeductions((prevAddOn) => prevAddOn.filter((_, i) => i !== index));

    // Decrease numForms but ensure at least 1 form remains
    setNumFormsD((prevNumForms) => Math.max(prevNumForms - 1, 1));

    // Store removed ID if available
    if (id) {
      setPFRemovedIdD((prevIds) => [...prevIds, id]);
    }
  };
  useEffect(() => {
    if (defaultValue) {
      setValue("borrower_id", defaultValue?.borrower_id);
      setValue("disbursement_amount", defaultValue?.disbursement_amount);
      setValue("request_remarks", defaultValue?.request_remarks);
    }
  }, [defaultValue, setValue, borrowerList]);
  const onSubmit = async (data) => {
    const hasEmptyFields = addOn.some(
      (item) => !item.description || !item.amount
    );
    if (hasEmptyFields) {
      showToast(
        "All add-on fields (Description & Amount) are required.",
        "error"
      );
      setReqq(true);
      return;
    }
    const hasEmptyFieldsD = deductions.some(
      (item) => !item.description || !item.amount
    );
    if (hasEmptyFieldsD) {
      showToast(
        "All deductions fields (Description & Amount) are required.",
        "error"
      );
      setReqq(true);
      return;
    }
    const formData = new FormData();
    formData.append("borrower_id", data.borrower_id);
    formData.append("disbursement_amount", data.disbursement_amount);
    formData.append("request_remarks", data.request_remarks);

    formData.append("add_on", JSON.stringify(addOn));
    formData.append("deductions", JSON.stringify(deductions));
    const fileFields = [""];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const oversizedFiles = [];
    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }

    try {
      const apiEndpoint = defaultValue
        ? `${baseURL}admin/update-disbursement-request/${defaultValue?.id}`
        : `${baseURL}admin/raise-disbursement-request`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      navigate("/disbursement-request");
    } catch (error) {
      const { message, errors } = error.response.data;
      showToast(message, "error");
    }
  };
  const handleSelectChange = (e, id, index) => {
    const { value } = e.target;
    setAddOn((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], description: value };
      return updated;
    });
  };
  const handleSelectChangeD = (e, id, index) => {
    const { value } = e.target;
    setDeductions((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], description: value };
      return updated;
    });
  };
  const handleAmountChange = (e, index) => {
    const { value } = e.target;
    setAddOn((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], amount: value };
      return updated;
    });
  };
  const handleAmountChangeD = (e, index) => {
    const { value } = e.target;
    setDeductions((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], amount: value };
      return updated;
    });
  };
  const renderFormFields = () => {
    const formFields = [];

    for (let i = 0; i < numForms; i++) {
      formFields.push(
        <div className={styles.totalcheckconnn} key={i}>
          <div className={styles.searorinp}>
            <label
              style={{ fontSize: "12px", color: "#000000", fontWeight: "bold" }}
            >
              Description<span className={styles.requ}>*</span>
            </label>
            <select
              className={styles.inpttsc}
              style={{ height: "30px" }}
              value={addOn[i]?.description || ""}
              onChange={(e) =>
                handleSelectChange(e, `bonusList[${i}].description`, i)
              }
            >
              <option value="">--Select Description--</option>
              <option value="IOT">IOT</option>
              <option value="2nd Year Insurance">2nd Year Insurance</option>
            </select>
            {reqq && !addOn[i]?.description && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{ fontSize: "12px", color: "#000000", fontWeight: "bold" }}
            >
              Amount<span className={styles.requ}>*</span>
            </label>
            <input
              className={styles.inpttsc}
              type="number"
              step="0.01"
              value={addOn[i]?.amount || ""}
              onChange={(e) => handleAmountChange(e, i)}
            />
            {reqq && !addOn[i]?.amount && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>

          <div
            className={styles.actionCont}
            style={{
              display: "flex",
              width: "100px",
              textAlign: "left",
              gap: "10px",
            }}
          >
            {numForms > 1 && (
              <FaMinusSquare
                style={{ position: "relative", top: "23px" }}
                className={styles.plusIcon}
                onClick={() => handleMinusForm(i, watch(`bonusList[${i}].id`))}
              />
            )}
            {(numForms === 1 || i === numForms - 1) && (
              <FaPlusSquare
                style={{ position: "relative", top: "23px" }}
                className={styles.plusIcon}
                onClick={handleAddForm}
              />
            )}
          </div>
        </div>
      );
    }

    return formFields;
  };
  const renderDeductionFormFields = () => {
    const formFieldsD = [];

    for (let i = 0; i < numFormsD; i++) {
      formFieldsD.push(
        <div className={styles.totalcheckconnn} key={i}>
          <div className={styles.searorinp}>
            <label
              style={{ fontSize: "12px", color: "#000000", fontWeight: "bold" }}
            >
              Description<span className={styles.requ}>*</span>
            </label>
            <select
              className={styles.inpttsc}
              style={{ height: "30px" }}
              value={deductions[i]?.description || ""}
              onChange={(e) =>
                handleSelectChangeD(e, `bonusListt[${i}].description`, i)
              }
            >
              <option value="">--Select Description--</option>
              <option value="IOT">IOT</option>
              <option value="2nd Year Insurance">2nd Year Insurance</option>
            </select>
            {reqq && !deductions[i]?.description && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>

          <div className={styles.searorinp}>
            <label
              style={{ fontSize: "12px", color: "#000000", fontWeight: "bold" }}
            >
              Amount<span className={styles.requ}>*</span>
            </label>
            <input
              className={styles.inpttsc}
              type="number"
              step="0.01"
              value={deductions[i]?.amount || ""}
              onChange={(e) => handleAmountChangeD(e, i)}
            />
            {reqq && !deductions[i]?.amount && (
              <span className={styles.error}>This Field is Required.</span>
            )}
          </div>

          <div
            className={styles.actionCont}
            style={{
              display: "flex",
              width: "100px",
              textAlign: "left",
              gap: "10px",
            }}
          >
            {numFormsD > 1 && (
              <FaMinusSquare
                style={{ position: "relative", top: "23px" }}
                className={styles.plusIcon}
                onClick={() =>
                  handleMinusFormD(i, watch(`bonusListt[${i}].id`))
                }
              />
            )}
            {(numFormsD === 1 || i === numFormsD - 1) && (
              <FaPlusSquare
                style={{ position: "relative", top: "23px" }}
                className={styles.plusIcon}
                onClick={handleAddFormD}
              />
            )}
          </div>
        </div>
      );
    }

    return formFieldsD;
  };
  return (
    <div style={{ marginBottom: "15px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc} style={{ gap: "8px" }}>
          {dealerDet?.data && (
            <div
              className={styles.totalcheckconnn}
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                borderRadius: "5px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                marginBottom: "12px",
              }}
            >
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Borrower Name
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.borrower_name}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Product Name
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.product_name}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Product Type
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.product_type}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Battery Type
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.battery_type}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Loan Amount
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.loan_amount}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Loan EMI
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.loan_emi}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Tenure
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.tenure}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Loan Interest Amount
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.total_loan_interest}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Down Payment
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.scheme_details?.down_payment}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  FLDG
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.scheme_details?.fldg}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  IOT
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.scheme_details?.iot}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Processing Fee
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.scheme_details?.processing_fee}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Subvention amount
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.scheme_details?.subvention_amount}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Insurance 1st Year
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.scheme_details?.insurance_1st_year}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Insurance 2nd Year
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.scheme_details?.insurance_2nd_year}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Interest
                </label>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "normal",
                  }}
                >
                  {dealerDet?.data?.scheme_details?.interest}
                </label>
              </div>
            </div>
          )}

          <div
            className={styles.totalcheckconnn}
            style={{ paddingBottom: "10px", borderBottom: "1px solid #ddd" }}
          >
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Borrower<span className={styles.requ}>*</span>
              </label>

              <Controller
                control={control}
                rules={{ required: "This Field is Missing" }}
                render={({ field: { onChange, value } }) => (
                  <ListWithSearc
                    options={borrowerList?.data || []}
                    getOptionLabel={(val) => val.borrower_id || ""}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={option.id}
                      >{`${option.borrower_id}`}</li>
                    )}
                    className={styles.listSrch}
                    onChange={(event, selectedValue) => {
                      onChange(selectedValue ? selectedValue?.id : null);
                    }}
                    value={
                      borrowerList?.data?.find(
                        (employee) => employee.id === value
                      ) || null
                    }
                  />
                )}
                name={`borrower_id`}
              />

              {errors.borrower_id && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Disbursement Amount <span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("disbursement_amount", {
                  required: true,
                })}
              />

              {errors.disbursement_amount && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Request Remarks<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("request_remarks", {
                  required: true,
                })}
              />

              {errors.request_remarks && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>

          <p
            style={{ textAlign: "left", fontWeight: "bold", color: "#12468a" }}
          >
            ADD ONS
          </p>
          {renderFormFields()}
          <div
            className={styles.totalcheckconnn}
            style={{ borderBottom: "1px solid #ddd", paddingBottom: "5px" }}
          >
            <div className={styles.searorinp}></div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Total
              </label>
              <input className={styles.inpttsc} value={totalAmount} />
            </div>
          </div>

          <p
            style={{ textAlign: "left", fontWeight: "bold", color: "#12468a" }}
          >
            DEDUCTIONS
          </p>
          {renderDeductionFormFields()}
          <div
            className={styles.totalcheckconnn}
            style={{ borderBottom: "1px solid #ddd", paddingBottom: "5px" }}
          >
            <div className={styles.searorinp}></div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Total
              </label>
              <input className={styles.inpttsc} value={totalAmountD} />
            </div>
          </div>

          <div className={styles.buttonasc}>
            <button
              type="submit"
              className={styles.Subbtn}
              style={{
                position: "relative",
                bottom: "10px",
              }}
            >
              {defaultValue ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RaiseReq;
