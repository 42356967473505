import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";

const RejDetails = ({ addHandle, defaultValue }) => {
  console.log("defaultValue in rejre", defaultValue);

  return (
    <div
      style={{ width: "750px" }}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Reason For Rejection</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <div className={styles.twoninputcont}>
            <div className={styles.inptconnt}>
              {defaultValue &&
                defaultValue?.reason &&
                defaultValue?.reason?.dpd && (
                  <label
                    style={{
                      marginBottom: "0.5rem",
                      color: "#494646",
                      fontSize: "13px",
                    }}
                  >
                    Dpd In First Year :{" "}
                    {defaultValue?.reason?.dpd?.dpd_in_1_year}
                    <br />
                    Dpd In Second Year :{" "}
                    {defaultValue?.reason?.dpd?.dpd_in_2_year}
                    <br />
                    Dpd In Third Month :{" "}
                    {defaultValue?.reason?.dpd?.dpd_in_3_month}
                    <br />
                    Dpd In Third Year :{" "}
                    {defaultValue?.reason?.dpd?.dpd_in_3_year} <br />
                    Is Willfull Defaulter :{" "}
                    {defaultValue?.reason?.is_willful_defaulter ? "Yes" : "No"}
                  </label>
                )}
              {defaultValue && defaultValue?.admin_remarks && (
                <label
                  style={{
                    marginBottom: "0.5rem",
                    color: "#494646",
                    fontSize: "13px",
                  }}
                >
                  {defaultValue?.admin_remarks}
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejDetails;
