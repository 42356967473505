import React, { useContext, useEffect } from "react";
import styles from "../CreateDealer/BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import ListWithSearc from "../../Common/ListWithSearch/ListWithSearc";
const DealerForm = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  const [perm, setPerm] = useState([]);
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/3");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  const {
    register,
    handleSubmit,
    clearErrors,
    clearError,
    unregister,
    setValue,
    control,
    watch,

    formState: { errors },
  } = useForm();

  const { showToast } = useToast();
  const { token, baseURL } = useContext(AuthContext);
  const fetchData = async () => {
    const response = await api.get(`edit-dealer/${defaultValue.id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("existingData", existingData);
  const companyType = watch("company_type");
  console.log("companyType", companyType);
  const [{ data: StateList }] = useQueries({
    queries: [
      {
        queryKey: ["statelist"],
        queryFn: () =>
          api.get("admin/get-state-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  // const handleCompanyTypeChange = (e) => {
  //   const selectedType = e.target.value;
  //   setValue("company_type", selectedType);
  //   clearErrors("balance_sheet");
  //   clearErrors("moa_and_aoa");
  //   clearErrors("certificate_of_incorporation");
  //   clearErrors("factory_address_proof");
  //   clearErrors("partnership_deed");
  //   clearError("balance_sheet");
  //   clearError("moa_and_aoa");
  //   clearError("certificate_of_incorporation");
  //   clearError("factory_address_proof");
  //   clearError("partnership_deed");
  // };
  useEffect(() => {
    if (existingData) {
      setValue("name", existingData?.name);
      setValue("date_of_incorporation", existingData?.date_of_incorporation);
      setValue("mobile", existingData?.mobile);
      setValue("alternative_number", existingData?.alternative_number);
      setValue("email", existingData?.email);
      setValue("pan_number", existingData?.pan_number);
      // setValue("aadhar_number", existingData?.aadhar_number);
      setValue("landmark", existingData?.landmark);
      setValue("city", existingData?.city);
      setValue("pincode", existingData?.pincode);
      setValue("address_line_1", existingData?.address_line_1);
      setValue("address_line_2", existingData?.address_line_2);
      setValue("company_type", existingData?.company_documents?.company_type);
      // setValue("dealer_loi", existingData?.dealer_loi);
    }
  }, [existingData, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append non-file fields
    formData.append("name", data.name);
    formData.append("date_of_incorporation", data.date_of_incorporation);
    formData.append("mobile", data.mobile);
    formData.append("alternative_number", data.alternative_number);
    formData.append("email", data.email);
    formData.append("pan_number", data.pan_number);
    // formData.append("aadhar_number", data.aadhar_number);
    formData.append("landmark", data.landmark);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("pincode", data.pincode);
    formData.append("address_line_1", data.address_line_1);
    formData.append("address_line_2", data.address_line_2);
    formData.append("password", data.password);
    formData.append("company_type", data.company_type);
    if (defaultValue && defaultValue.id) {
      formData.append("id", defaultValue.id);
    }
    // Append file fields
    const fileFields = [
      "bank_statement",
      // "trade_certificate",
      "gst_certificate",
      "security_cheque_1",
      "security_cheque_2",

      "security_cheque_3",
      "security_cheque_4",
      "rent_agreement",
      "lsp_finayo_agreement",
      // "rent_agreement",
      "office_premise_img",
      // "dealer_image_2",
      // "showroom_img_1",
      // "showroom_img_2",
      // "showroom_img_3",
      // "showroom_img_4",
      "itr_file",
      "pan",
      // "aadhar",
      "balance_sheet",
      // "icat_certificate",
      "utility_bill",
      // "dealer_loi",
      "partnership_deed",
      // "manufacturing_license",
      "moa",
      "aoa",
      "certificate_of_incorporation",
      "factory_address_proof",
    ];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    // fileFields.forEach((field) => {
    //   if (data[field] && data[field].length > 0) {
    //     // If new file is uploaded, append it
    //     formData.append(field, data[field][0]);
    //   } else if (defaultValue && defaultValue[field]) {
    //     // If no new file is uploaded, append the existing file value
    //     formData.append(field, defaultValue[field]);
    //   }
    // });

    try {
      // const response = await axios.post(
      //   "https://insta.finayo.tech/api/store-dealer",
      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      const apiEndpoint = defaultValue
        ? `${baseURL}admin/update-loan-service-provider`
        : `${baseURL}admin/store-loan-service-provider`;
      // ? `https://insta.finayo.tech/api/update-dealer`
      // : "https://insta.finayo.tech/api/store-dealer";

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      navigate("/loan-service-provider");
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;
      console.log("message", message);
      setErr(message);
      showToast(message, "error");
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  //   if (!defaultValue || !defaultValue.name) {
  //     register("trade_certificate", { required: true });
  //   } else {
  //     register("trade_certificate");
  //   }

  // useEffect(() => {
  //   if (existingData) {
  //     console.log("data exist");
  //     // if (!existingData?.trade_certificate) {
  //     //   register("trade_certificate", { required: true });
  //     // } else {
  //     //   register("trade_certificate");
  //     // }

  //     const fileFields = [
  //       "bank_statement",
  //       "trade_certificate",
  //       "gst_certificate",
  //       "security_cheque_1",
  //       "security_cheque_2",
  //       "security_cheque_3",
  //       "security_cheque_4",
  //       "rent_agreement",
  //       "office_premise_img",
  //       "dealer_image_2",
  //       "showroom_img_1",
  //       "showroom_img_2",
  //       "showroom_img_3",
  //       "showroom_img_4",
  //       "itr_file",
  //       "dealer_loi",
  //       // "balance_sheet",
  //       "icat_certificate",
  //       "utility_bill",
  //       // "partnership_deed",
  //       // "manufacturing_license",
  //       // "moa_and_aoa",
  //       // "certificate_of_incorporation",
  //       // "factory_address_proof",
  //       "pan",
  //       "aadhar",
  //     ];

  //     fileFields.forEach((field) => {
  //       if (!existingData[field]) {
  //         register(field, { required: true });
  //       } else {
  //         register(field);
  //       }
  //     });
  //   }
  // }, [register, existingData]);
  useEffect(() => {
    if (!existingData) {
      console.log("data doesnot exist");
      const requiredFields = [
        // "bank_statement",
        // "trade_certificate",
        "gst_certificate",
        "security_cheque_1",
        "security_cheque_2",
        "security_cheque_3",
        "security_cheque_4",
        // "rent_agreement",
        "office_premise_img",
        "lsp_finayo_agreement",
        // "dealer_image_2",
        // "showroom_img_1",
        // "showroom_img_2",
        // "showroom_img_3",
        // "showroom_img_4",
        // "itr_file",
        // "dealer_loi",
        // "icat_certificate",
        "utility_bill",
        "pan",
        // "aadhar",
      ];

      requiredFields.forEach((field) => {
        register(field, { required: true });
      });
    }
  }, [register, existingData]);
  console.log("companyType", companyType);
  useEffect(() => {
    if (companyType === "PARTNERSHIP") {
      register("partnership_deed", { required: true });
    } else {
      register("partnership_deed");
    }

    if (companyType === "PRIVATE LIMITED") {
      register("balance_sheet");
      register("moa", { required: true });
      register("aoa", { required: true });
      register("certificate_of_incorporation", { required: true });
      // register("factory_address_proof");
    } else {
      register("balance_sheet");
      register("moa");
      register("aoa");
      register("certificate_of_incorporation");
      // register("factory_address_proof");
    }
  }, [companyType, register]);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "10px" }}>
        <div className={styles.totalcheckconnn}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Company Type<span className={styles.requ}>*</span>
            </label>
            <select
              // onChange={handleCompanyTypeChange}
              style={{ height: "24px" }}
              className={styles.inputstst}
              {...register("company_type", {
                required: true,
              })}
            >
              <option value="">--Select Company Type--</option>
              <option value="PROPRIETORSHIP">PROPRIETORSHIP</option>
              <option value="PARTNERSHIP">PARTNERSHIP</option>
              <option value="PRIVATE LIMITED">PRIVATE LIMITED</option>
            </select>
            {errors.company_type && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Name<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Name"
              type="text"
              className={styles.inputstst}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date Of Incorporation<span className={styles.requ}>*</span>
              </label>
              <input
                type="date"
                className={styles.inputstst}
                {...register("date_of_incorporation", { required: true })}
              />
              {errors.date_of_incorporation && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile Number<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Mobile Number"
              type="number"
              className={styles.inputstst}
              {...register("mobile", {
                required: true,
                minLength: {
                  value: 10,
                  message: "Please Enter 10 digits.",
                },
              })}
            />
            {errors.mobile && (
              <span className={styles.error}>
                {errors.mobile.message || "This Field is Missing."}
              </span>
            )}
            <span className={styles.error}>{err.mobile ? err.mobile : ""}</span>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Alternate Mobile Number
            </label>
            <input
              placeholder="Enter Alternate Mobile Number"
              type="number"
              className={styles.inputstst}
              {...register("alternative_number", {
                minLength: {
                  value: 10,
                  message: "Please Enter 10 digits.",
                },
              })}
            />

            {errors.alternative_number && (
              <span className={styles.error}>
                {errors.alternative_number.message || "This Field is Required."}
              </span>
            )}
          </div>
          {/* </div>
          <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Email<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Email"
              type="email"
              className={styles.inputstst}
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
            <span className={styles.error}>{err.email ? err.email : ""}</span>
          </div>
        </div>
        <div className={styles.totalcheckconnn} style={{ marginTop: "15px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 1<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Address Line 1"
              type="text"
              className={styles.inputstst}
              {...register("address_line_1", { required: true })}
            />
            {errors.address_line_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Address Line 2<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Address Line 2"
              type="text"
              className={styles.inputstst}
              {...register("address_line_2", { required: true })}
            />
            {errors.address_line_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              City<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter City"
              type="text"
              className={styles.inputstst}
              {...register("city", { required: true })}
            />
            {errors.city && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              State<span className={styles.requ}>*</span>
            </label>
            <Controller
              control={control}
              rules={{ required: "This Field is required" }}
              render={({ field: { onChange, value } }) => (
                <ListWithSearc
                  options={StateList?.data || []}
                  getOptionLabel={(val) => val.state || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>{`${option.state}`}</li>
                  )}
                  className={styles.listSrchzddfsdf}
                  onChange={(event, selectedValue) => {
                    onChange(selectedValue ? selectedValue?.id : null);
                  }}
                  value={
                    StateList?.data?.find(
                      (employee) => employee.id === value
                    ) || null
                  }
                />
              )}
              name={`state`}
            />
            {errors.state && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Pincode<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Pincode"
              type="number"
              className={styles.inputstst}
              {...register("pincode", {
                required: true,
                pattern: {
                  value: /^\d{6}$/,
                  message: "Please Enter Valid Pincode",
                },
              })}
            />
            {errors.pincode && (
              <span className={styles.error}>
                {errors.pincode.message || "This Field is Missing."}
              </span>
            )}
          </div>{" "}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Landmark
            </label>
            <input
              placeholder="Enter Landmark"
              type="text"
              className={styles.inputstst}
              {...register("landmark")}
            />
            {errors.landmark && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {/* </div>
          <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN No.<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter PAN Number"
              type="text"
              className={styles.inputstst}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              {...register("pan_number", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9]{10}$/,
                  message: "Please Enter Valid PAN Number",
                },
              })}
            />
            {errors.pan_number && (
              <span className={styles.error}>
                {errors.pan_number.message || "This Field is Missing."}
              </span>
            )}
            {/* {errors.pan_number && (
                <span className={styles.error}>This Field is Missing.</span>
              )} */}
            <span className={styles.error}>
              {err.pan_number ? err.pan_number : ""}
            </span>
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Upload PAN <span className={styles.requ}>*</span>
            </label>
            <input
              className={styles.uploadbx}
              {...register("pan")}
              type="file"
              // accept="application/pdf"
              // onChange={handleChange("bank_statement")}
              // onChange={(e) => {
              //   fileChangeHandler(e);
              // }}
            />

            {errors.pan && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
            {/* {errors.bank_statement && (
                <span className={styles.error}>This Field is Required.</span>
              )} */}
          </div>
          {/* <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Dealer LOI<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("dealer_loi")}
             
            />
           
            {errors.dealer_loi && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>{" "} */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              LSP Finayo Agreement<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("lsp_finayo_agreement")}
              accept="application/pdf"
              //    onChange={handleChange("trade_certificate")}
            />

            {errors.lsp_finayo_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Bank Statement
            </label>
            <input
              className={styles.uploadbx}
              {...register("bank_statement")}
              type="file"
              accept="application/pdf"
              // onChange={handleChange("bank_statement")}
              // onChange={(e) => {
              //   fileChangeHandler(e);
              // }}
            />

            {errors.bank_statement && (
              <span className={styles.error}>
                {errors.bank_statement.message || "This Field is Missing."}
              </span>
            )}
          </div>
          {/* </div>
          <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 1<span className={styles.requ}>*</span>
              {existingData && existingData?.security_cheque_1 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.security_cheque_1)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("security_cheque_1")}
              accept="image/jpeg, image/png,image/jpg"
              //  onChange={handleChange("security_cheque_1")}
            />
            {errors.security_cheque_1 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 2<span className={styles.requ}>*</span>
              {existingData && existingData?.security_cheque_2 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.security_cheque_2)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("security_cheque_2")}
              accept="image/jpeg, image/png,image/jpg"
              // onChange={handleChange("security_cheque_2")}
            />
            {errors.security_cheque_2 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 3<span className={styles.requ}>*</span>
              {existingData && existingData?.security_cheque_3 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.security_cheque_3)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("security_cheque_3")}
              accept="image/jpeg, image/png,image/jpg"
              // onChange={handleChange("security_cheque_3")}
            />
            {errors.security_cheque_3 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {/* </div>
          <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Security Cheque 4<span className={styles.requ}>*</span>
              {existingData && existingData?.security_cheque_4 && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData?.security_cheque_4)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("security_cheque_4")}
              accept="image/jpeg, image/png,image/jpg"
              //onChange={handleChange("security_cheque_4")}
            />
            {errors.security_cheque_4 && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              GST Certificate<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("gst_certificate")}
              // onChange={handleChange("gst_certificate")}
              accept="application/pdf"
              //onChange={handleChange("gst_certificate")}
            />
            {errors.gst_certificate && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Rent Agreement
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("rent_agreement")}
              accept="application/pdf"
              //  onChange={handleChange("rent_agreement")}
            />
            {errors.rent_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Office Premise Image<span className={styles.requ}>*</span>
              {existingData && existingData?.office_premise_img && (
                <button
                  type="button"
                  onClick={() => handleEditRow(existingData.office_premise_img)}
                  className={styles.bbtnvofde}
                >
                  View
                </button>
              )}
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("office_premise_img")}
              accept="image/jpeg, image/png,image/jpg"
              //  onChange={handleChange("office_premise_img")}
            />
            {errors.office_premise_img && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              ITR File
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("itr_file")}
              // onChange={handleFileChange}
              accept="application/pdf"
              //   onChange={handleChange("itr_file")}
            />
            {errors.itr_file && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Utility Bill<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("utility_bill")}
              // accept="image/jpeg, image/png,image/jpg"
              accept="application/pdf"
              // onChange={fileChangeHandler}
              //  onChange={handleChange("balance_sheet")}
            />
            {errors.utility_bill && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {/* </div>
          <div className={styles.threeinputads}> */}
          {/* </div> */}
          {companyType === "PARTNERSHIP" && (
            // <div className={styles.oneinputads}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Partnership Deed<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("partnership_deed")}
                // accept="image/jpeg, image/png,image/jpg"
                // accept="application/pdf"
                // onChange={fileChangeHandler}
                //  onChange={handleChange("balance_sheet")}
              />
              {errors.partnership_deed && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            // </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            // <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Balance Sheet
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("balance_sheet")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.balance_sheet && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                MOA<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("moa")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.moa && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                AOA<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("aoa")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.aoa && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Certificate Of Incorporation
                <span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.uploadbx}
                {...register("certificate_of_incorporation")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.certificate_of_incorporation && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            // </div>
          )}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Password<span className={styles.requ}>*</span>
            </label>
            <input
              placeholder="Enter Password"
              type="password"
              className={styles.inputstst}
              {...register("password", {
                required: true,
                minLength: {
                  value: 8,
                  message: "Too Short",
                },
              })}
            />
            {errors.password && (
              <span className={styles.error}>
                {errors.password.message || "This Field is Misiing"}
              </span>
            )}
          </div>
        </div>
        {perm?.includes("create") && (
          <div className={styles.buttonasc} style={{ marginTop: "10px" }}>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default DealerForm;
