import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import classes from "./Language.module.css";
import { LoanContext } from "../../../context/LoanContext";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { useToast } from "../../../context/ToastProvder";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";
const BorrowerFamily = ({ setFamilyStep }) => {
  const {
    userD,
    handleNext,
    borrId,
    dataFromAdhar,
    setDataFromAdhar,
    setUserD,
  } = useContext(LoanContext);
  const [add, setAdd] = useState(null);
  const [proId, setProId] = useState(null);
  const { token, baseURL } = useContext(AuthContext);
  const {
    register,

    handleSubmit,

    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const restype = watch("resident_type");

  const [{ data: resType }] = useQueries({
    queries: [
      {
        queryKey: ["resType"],
        queryFn: () => api.get("get-resident-types").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (borrId) {
      const fetchBorrowerDetails = async () => {
        try {
          const response = await api.post("admin/get-user-address-details", {
            borrower_id: borrId,
            user_type: "BORROWER",
          });
          //  console.log(response.data.data);
          setAdd(response.data.data);
        } catch (error) {
          //  console.error("Error fetching borrower details:", error);
        }
      };

      fetchBorrowerDetails();
    }
  }, [borrId]);

  useEffect(() => {
    if (add || dataFromAdhar) {
      const address = dataFromAdhar?.address || add?.single_line_address;
      setValue("permanent_address", address || ""); // Default to empty string if both are undefined
    }
  }, [dataFromAdhar, add, setValue]);

  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //  console.log("Loan Status:", response.data);

          const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setProId(product_id);
          //  if (step === 3) {
          //    setFamilyStep(true);
          //  }
        })
        .catch((error) => {
          // console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const onSubmit = async (data) => {
    if (
      parseInt(data.number_of_earning_members) >
      parseInt(data.number_of_family_members)
    ) {
      showToast(
        "The Earning Members cannot be more than the Family members.",
        "error"
      );
      return;
    }
    const formData = new FormData();
    formData.append("borrower_id", borrId);
    formData.append("resident_type", data.resident_type);
    formData.append("current_address", data.current_address);
    formData.append("permanent_address", data.permanent_address);
    formData.append("years_living", data.years_living);
    formData.append("number_of_family_members", data.number_of_family_members);
    formData.append(
      "number_of_earning_members",
      data.number_of_earning_members
    );
    formData.append("family_income", data.family_income);
    const fileFields = ["rent_agreement"];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    try {
      const apiEndpoint = `${baseURL}admin/store-borrower-family-details`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      handleNext();
    } catch (error) {
      // console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;

      showToast(message, "error");
    }
  };
  const isSameAddressChecked = watch("same_address");
  useEffect(() => {
    if (isSameAddressChecked) {
      setValue("current_address", watch("permanent_address"));
    } else {
      setValue("current_address", "");
    }
  }, [isSameAddressChecked, watch("permanent_address"), setValue]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className={classes.applheader}>BORROWER FAMILY DETAILS :</p>
      <div className={classes.totalcheckconnn}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Resident Type<span className={classes.requ}>*</span>
          </label>
          <select
            {...register("resident_type", { required: true })}
            className={classes.inputstst}
            style={{ height: "29px" }}
          >
            <option value="">--Select Resident Type--</option>
            {proId === "4" ? (
              <option value="Owned">Owned</option>
            ) : (
              resType?.data?.map((row) => (
                <option key={row} value={row}>
                  {row}
                </option>
              ))
            )}
          </select>
          {errors.resident_type && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Permanent Address<span className={classes.requ}>*</span>
          </label>
          <textarea
            style={{ height: "35px" }}
            placeholder="Enter Permanent Address"
            {...register("permanent_address", { required: true })}
            type="text"
            disabled
            className={classes.inputstst}
          />

          {errors.permanent_address && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Present/Communication Address
            <span className={classes.requ}>*</span>
          </label>
          <textarea
            style={{ height: "35px" }}
            placeholder="Enter Present/Communication Address"
            {...register("current_address", { required: true })}
            type="text"
            className={classes.inputstst}
          />

          {errors.current_address && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div style={{ paddingTop: "15px", textAlign: "left" }}>
          <input
            {...register("same_address")}
            type="checkbox"
            className={classes.inputstst}
          />
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              position: "relative",
              bottom: "3px",
            }}
          >
            Same as Permanent Address
          </label>

          {errors.name && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Years Leaving<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Years Leaving"
            {...register("years_living", {
              required: "Minimum 3 years required.",
              min: {
                value: 3,
              },
            })}
            // {...register("years_living", { required: true })}
            type="number"
            className={classes.inputstst}
          />

          {errors.years_living && (
            <span className={classes.error}>Minimum 3 years required.</span>
          )}
        </div>
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Family Members<span className={classes.requ}>*</span>
            </label>
            <input
              placeholder="Enter Family Members"
              {...register("number_of_family_members", {
                required: "This Field is Missing.",
                min: {
                  value: 1,
                },
              })}
              // {...register("number_of_family_members", { required: true })}
              type="number"
              className={classes.inputstst}
            />

            {errors.number_of_family_members && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Earning Members<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Earning  Members"
            {...register("number_of_earning_members", {
              required: "This Field is Missing.",
              min: {
                value: 1,
              },
            })}
            type="number"
            className={classes.inputstst}
          />

          {errors.number_of_earning_members && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Annual Family Income<span className={classes.requ}>*</span>
          </label>
          <input
            placeholder="Enter Annual Family Income"
            {...register("family_income", {
              required: "Income must be greater than/equal to 180000",
              min: {
                value: 180000,
                message: "Income must be greater than/equal to 180000",
              },
            })}
            type="number"
            className={classes.inputstst}
          />

          {errors.family_income && (
            <span className={classes.error}>
              Income must be greater than/equal to 180000
            </span>
          )}
        </div>
        {restype === "Rented" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Rent Agreement<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("rent_agreement", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg , application/pdf"
              className={classes.uploadbxscff}
            />
            {errors.rent_agreement && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
      </div>{" "}
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        <button type="submit" className={classes.Subbtn}>
          Submit
        </button>
      </div>
    </form>
  );
};

export default BorrowerFamily;
