import React, { useEffect, useState } from "react";
import styles from "../UserType/Modal.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
const EmiChart = ({ addHandle, defaultValue }) => {
  const fetchPermissions = async () => {
    let payload;
    payload = {
      //  borrower_id: 436,
      borrower_id: defaultValue,
    };
    //  const response = await api.get(`get-emi-chart/${defaultValue.id}`);
    const response = await api.get("get-emi-chart", { params: payload });
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["editData", defaultValue],
    queryFn: fetchPermissions,
  });
  const { data: permissionsData } = PermissionsResponse;
  console.log("permissionsData", permissionsData);
  return (
    <div
      className={styles.modalcontainer}
      style={{ width: "740px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>EMI Chart</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{
          borderBotom: "1px solid #red",
          height: "420px",
          overflowY: "scroll",
        }}
      >
        <table>
          <thead
            style={{
              position: "sticky",
              top: "0px",
            }}
          >
            <tr>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Installment
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Due Date
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Payment Date
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                EMI Amount
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Total Amount
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "7.5px",
                }}
              >
                Payment Status
              </th>

              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Principal Amount
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Interest Amount
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Fine Amount
              </th>

              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Receipt No
              </th>

              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Payment Mode
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Reference
              </th>
              <th
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "10px",
                }}
              >
                Remarks
              </th>
            </tr>
          </thead>
          <tbody>
            {permissionsData?.data?.emis?.map((row, index) => (
              <tr key={index}>
                <td>{row.number_of_installment}</td>
                <td>{row.due_date}</td>
                <td>{row.payment_date}</td>
                <td>{row.emi_amount}</td>
                <td>{row.total_payment}</td>
                <td>{row.payment_status}</td>
                <td>{row.principal_amount}</td>
                <td>{row.interest_amount}</td>
                <td>{row.fine_amount}</td>
                <td>{row.receipt_no}</td>
                <td>{row.payment_mode}</td>
                <td>{row.reference}</td>
                <td>{row.remarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            {/* <button type="submit" className={styles.Subbtn}>
              Submit
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmiChart;
