import React, { useState } from "react";
import profl from "./../../../icons/profl.jpg";
import styles from "./SeprateDet.module.css";
import { Backdrop } from "@mui/material";
import GuaModal from "./GuaModal";
import { useNavigate } from "react-router-dom";
import { BiSolidEdit } from "react-icons/bi";
import { useToast } from "../../../context/ToastProvder";
import api from "../../Common/api";
import { QueryClient } from "@tanstack/react-query";
const LenGuaDetails = ({ Detailsb, borrowerId, perm }) => {
  console.log("perm in gyua", perm);

  const [rowToEdit, setRowToEdit] = useState(null);
  const [add, setAdd] = useState(false);
  console.log("Detailsb", Detailsb);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const navigate = useNavigate();
  const EditHandle = () => {
    navigate("/edit-guarantor-documents", {
      state: { borrowerId },
    });
  };
  const { showToast } = useToast();
  const handleLenderDocDownload = async (borrowerId, docType) => {
    try {
      const payload = {
        borrower_id: Detailsb?.loan_details?.id,
        // borrower_id: borrowerId,
        doc_type: docType,
      };

      await api.post(`admin/handle-lender-doc-download`, payload);
      console.log(`Successfully logged download for ${docType}`);
      QueryClient.invalidateQueries("bankDetailsList");
    } catch (error) {
      console.error(`Error logging download for ${docType}:`, error);
    }
  };
  const handleDownloadBureau = async () => {
    if (Detailsb?.loan_details?.bureau_guarantor_download_left <= 0) {
      showToast("You have exceeded the download limit.", "error");
      return;
    }
    try {
      const response = await api.get(
        `admin/get-bureau-report-download-link/${borrowerId}/GUARANTOR`
      );

      if (
        response.data.status === "success" &&
        response.data.data.download_url
      ) {
        const downloadUrl = response.data.data.download_url;
        console.log("downloadUrl", downloadUrl);

        const fileResponse = await fetch(downloadUrl);
        const blob = await fileResponse.blob();

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "BureauReportGuarantor.xlsx");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
        await handleLenderDocDownload(borrowerId, "bureau_guarantor");
        const successMessage = response.data.message || "Download successful!";
        showToast(successMessage, "success");
      } else {
        console.error("Error: Download URL not found in response.");
      }
    } catch (error) {
      console.error("Error downloading the report:", error);
      const { message, errors } = error.response.data;
      console.log("message", message);
      showToast(message, "error");
    }
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <GuaModal
          addHandle={addHandle}
          doc={Detailsb?.documents || {}}
          type="guarantor"
          borrowerId={borrowerId}
        />
      </Backdrop>
      <div style={{ textAlign: "right" }}>
        {" "}
        {/* <button
          style={{
            padding: "5px 7px",
            color: "#fff",
            background: "#f06548",
            border: "none",
            margin: "8px",
            borderRadius: "4px",
            fontSize: "11px",
            height: "24px",
            position: "relative",
            top: "3px",
          }}
          onClick={EditHandle}
        >
          <BiSolidEdit
            size="14"
            // style={{ position: "relative", top: "3px" }}
          />{" "}
          <span style={{ position: "relative", bottom: "3px" }}>
            {" "}
            Edit Guarantor Document
          </span>
        </button> */}
        {perm?.includes("export") && (
          <button
            style={{
              margin: "8px",
            }}
            className={styles.dnldbtnforreport}
            onClick={handleDownloadBureau}
          >
            Download Bureau Report
          </button>
        )}
        <button
          style={{
            margin: "8px",
          }}
          className={styles.dnldbtnforreport}
          onClick={addHandle}
        >
          View Document
        </button>
      </div>
      <div className={styles.brwdettcon}>
        <div className={styles.wholetxtcon}>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Guarantor Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.name || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Mobile No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.mobile || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Date Of Birth</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.dob_date || ""}
              </p>
            </div>
            {/* <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Marital Status</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.marital_status || ""}
              </p>
            </div> */}
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Father's Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.father_name || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            {/* <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Spouse's Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.spouse_name || ""}
              </p>
            </div> */}

            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Gender</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.gender || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Alternate Number</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.mobile || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Email Id</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.email || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Pan No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.pan || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Aadhar No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.aadhar || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>City</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details?.city || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>State</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details?.full_address
                  ?.state || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Country</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_occupation?.address_details?.full_address
                  ?.country || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Cibil</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.credit_details?.guarantor_cibil_score}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Postal</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details?.full_address
                  ?.pc || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Current Occupation</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.current_occupation || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Monthly Earning</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.monthly_earning || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Occupation Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.occupation_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Total Income</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.total_income || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Vehicle Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.vehicle_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Single Line Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details
                  ?.single_line_address || ""}
              </p>
            </div>
            {/* <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Occupation Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.occupation_type || ""}
              </p>
            </div> */}
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>District</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details?.full_address
                  ?.dist || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Resident Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.resident_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Current Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.current_address || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Years Living At</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.years_living || ""}
              </p>
            </div>{" "}
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Permanent Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.permanent_address || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LenGuaDetails;
