import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { useNavigate } from "react-router-dom";
import Thankyou from "../../../icons/Thnaks.png";

import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";
const Step21 = () => {
  const [intitialStep, setInitialStep] = useState(false);
  const [stp, setStp] = useState(null);
  const [ifscDetails, setifscDetails] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  //   const [showGen, setShowGen] = useState(true);
  //  const [showRegenButton, setShowRegenButton] = useState(false);
  const {
    borrId,
    dealerId,
    borrwD,
    showGen,
    setShowGen,
    showRegenButton,
    setShowRegenButton,
    handleNext,
  } = useContext(LoanContext);
  const { token, baseURL, baseDoc, ver } = useContext(AuthContext);
  const { showToast } = useToast();
  const IfscNumber = watch("ifsc_code");
  const accNo = watch("account_number");
  const ConaccNo = watch("confirm_account_number");
  const navigate = useNavigate();
  const [{ data: emidebData }] = useQueries({
    queries: [
      {
        queryKey: ["emidata"],
        queryFn: async () => {
          const res = await api.get(
            `admin/get-emi-debit-consent-data/${borrId}`
          );
          return res.data?.data;
        },

        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //       console.log("Loan Status:", response.data);

          // const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setStp(step);
          //   if (step === 20) {
          //     //  setInitialStep(true);
          //     handleNext();
          //   }
          if (step === 21) {
            // setInitialStep(true);
            setShowGen(false);
            setShowRegenButton(true);
            // handleNext();
            setInitialStep(22);
          }
        })
        .catch((error) => {
          //  console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  useEffect(() => {
    if (IfscNumber?.length === 11) {
      api
        .get(`get-ifsc-info/${IfscNumber}`)
        .then((response) => {
          setifscDetails(response.data);
          showToast(response.data.message, "success");
        })
        .catch((error) => {
          const { message, errors } = error.response.data;
          //   console.log("message", message);
          showToast(message, "error");
        });
    }
  }, [IfscNumber]);
  const handleConcent = async (consent) => {
    try {
      const payload = {
        borrower_id: borrId,
        emi_debit_consent: consent,
      };

      const response = await api.post("admin/store-emi-debit-consent", payload);
      const { status, message } = response.data;
      if (response.status === 200 || response.data.status === "success") {
        //  console.log("API call successful:", response.data);
        // setInitialStep(true);
        handleNext();
        if (consent === "NO") {
          navigate("/dashboard");
        }
        showToast(message, status);
      }
    } catch (error) {
      const { message, data: errorDetails } = error.response.data;
      showToast(message, "error");
    }
  };
  const onSubmit = async (data) => {
    if (!data.concent_accepted) {
      showToast("Please accept the consent to proceed.", "error");
      return; // Prevent form submission if consent is not accepted
    }
    if (!ifscDetails) {
      showToast("IFSC code is Incorrect", "error");
      return; // Prevent form submission if consent is not accepted
    }
    const formData = new FormData();
    formData.append("borrower_id", borrId);
    formData.append("account_holder_name", data.account_holder_name);
    formData.append("account_number", data.account_number);
    formData.append("confirm_account_number", data.confirm_account_number);
    formData.append("ifsc_code", data.ifsc_code);
    formData.append("bank_name", data.bank_name);
    formData.append("branch_code", data.branch_code);
    formData.append("emi_debit_consent", "YES");
    try {
      const apiEndpoint = `${baseURL}admin/store-emi-debit-consent`;
      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      //  console.log("Form submitted successfully:", response.data);
      //  setInitialStep(true);
      handleNext();
    } catch (error) {
      //  console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;
      //  console.log("message", message);
      showToast(message, "error");
    }
  };
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setValue("concent_accepted", "Yes");
    } else {
      setValue("concent_accepted", "No");
    }
  };
  useEffect(() => {
    if (ifscDetails) {
      setValue("bank_name", ifscDetails?.data?.bank);
    }
  }, [ifscDetails]);
  useEffect(() => {
    if (emidebData) {
      setValue("account_holder_name", emidebData?.borrower_name);
    }
  }, [emidebData]);
  return (
    <div className={classes.tablco}>
      {" "}
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.tablco}>
          <p className={classes.applheader}>Borrower Account Details</p>
          <div className={classes.totalcheckconnn}>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Account Holder Name<span className={classes.requ}>*</span>
              </label>
              <input
                {...register("account_holder_name", {
                  required: true,
                  validate: (value) =>
                    /^[A-Za-z\s]+$/.test(value) ||
                    "Only letters and spaces are allowed.",
                })}
                disabled
                type="text"
                className={classes.inputstst}
              />

              {errors.account_holder_name && (
                <span className={classes.error}>
                  {errors.account_holder_name.message ||
                    "This Field is Missing."}
                </span>
              )}
            </div>

            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Account Number<span className={classes.requ}>*</span>
              </label>
              <input
                {...register("account_number", { required: true })}
                type="text"
                className={classes.inputstst}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setValue("account_number", value);
                }}
              />

              {errors.account_number && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Confirm Account Number<span className={classes.requ}>*</span>
              </label>
              <input
                {...register("confirm_account_number", { required: true })}
                type="text"
                className={classes.inputstst}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setValue("confirm_account_number", value);
                }}
              />

              {errors.account_number && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                IFSC Code<span className={classes.requ}>*</span>
              </label>
              <input
                {...register("ifsc_code", { required: true })}
                type="text"
                onChange={(e) => {
                  const value = e.target.value.toUpperCase(); // Capitalize input
                  setValue("ifsc_code", value); // Update the value using React Hook Form's setValue
                }}
                className={classes.inputstst}
              />

              {errors.ifsc_code && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Bank Name<span className={classes.requ}>*</span>
              </label>
              <input
                {...register("bank_name", { required: true })}
                type="text"
                disabled
                className={classes.inputstst}
              />
              {/* {ifscDetails && ifscDetails?.status === "success" && (
                  <span style={{ color: "green" }} className={classes.error}>
                    {ifscDetails?.message}
                  </span>
                )}
                {ifscDetails && ifscDetails?.status === "failed" && (
                  <span className={classes.error}>{ifscDetails?.message}</span>
                )} */}
              {errors.bank_name && (
                <span className={classes.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={classes.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Branch Code
              </label>
              <input
                {...register("branch_code")}
                type="text"
                className={classes.inputstst}
              />
            </div>
          </div>
          {accNo &&
            accNo === ConaccNo &&
            accNo?.length > 9 &&
            accNo?.length < 20 && (
              <div style={{ display: "flex", margin: "8px 0" }}>
                <input
                  type="checkbox"
                  // checked={concentAccepted === "Yes"}
                  onChange={handleCheckboxChange}
                  {...register("concent_accepted")}
                />

                <p
                  style={{
                    color: "#212529",
                    fontSize: "11px",
                    position: "relative",
                    top: "2.5px",
                    textAlign: "left",
                  }}
                >
                  {/* {relationList?.data} */}
                  I, {emidebData?.borrower_name || "-"}, residing at{" "}
                  {emidebData?.address}, having a bank account with{" "}
                  {ifscDetails?.bank_name}, Account Number:
                  {accNo}, hereby authorize {emidebData?.lender_name} to debit
                  my account for the EMI payments related to the loan sanctioned
                  to me.<br></br>
                  <span style={{ fontWeight: "bold" }}> Loan Details:</span>
                  <br></br>{" "}
                  <span style={{ fontWeight: "bold" }}>Loan Type: </span>
                  {emidebData?.loan_type || "-"} <br></br>
                  <span style={{ fontWeight: "bold" }}>Loan Amount: </span>
                  {emidebData?.loan_amount || "-"} <br></br>{" "}
                  <span style={{ fontWeight: "bold" }}>EMI Amount:</span>
                  {emidebData?.emi_amount || "-"} <br></br>
                  <span style={{ fontWeight: "bold" }}>EMI Due Date:</span>
                  {emidebData?.emi_due_date || "-"}
                  <br></br>
                  <span style={{ fontWeight: "bold" }}>
                    Loan Tenure: {emidebData?.loan_term || "-"}
                  </span>
                  <br></br> I understand that the EMI will be automatically
                  debited from my account on the{" "}
                  {emidebData?.emi_due_date || "-"} of every month until the
                  loan is fully repaid. In case of insufficient funds, I am
                  aware of the penalty/charge that may apply. I further confirm
                  that I have read and understood the terms and conditions
                  related to my loan and the EMI payment process.
                </p>
              </div>
            )}
          <div
            className={classes.buttonasc}
            style={{ marginTop: "10px", textAlign: "left" }}
          >
            {accNo &&
              accNo === ConaccNo &&
              accNo?.length > 9 &&
              accNo?.length < 20 && (
                <button
                  type="submit"
                  className={classes.Subbtn}
                  style={{ width: "90px" }}
                >
                  Submit
                </button>
              )}
          </div>
        </form>
      </div>
      {/* {intitialStep && (
        <AdditionalThirteen
          showGen={showGen}
          setShowGen={setShowGen}
          showRegenButton={showRegenButton}
          setShowRegenButton={setShowRegenButton}
        />
      )} */}
    </div>
  );
};

export default Step21;
