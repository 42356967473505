import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import classes from "./Language.module.css";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
import { AuthContext } from "../../../context/AuthContext";
import api from "../../Common/api";
const VehicDoc = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { borrId, handleNext, userD, userId, setActiveStep } =
    useContext(LoanContext);
  const { showToast } = useToast();
  const [proId, setProId] = useState(null);
  const { token, baseURL } = useContext(AuthContext);
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //   console.log("Loan Status:", response.data);

          const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setProId(product_id);
          // if (product_id === "4") {
          //   handleSkip();
          // }
        })
        .catch((error) => {
          //   console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("borrower_id", borrId);
    formData.append("down_payment", data?.down_payment);
    formData.append("chasis_number", data?.chasis_number);
    formData.append("insurance_policy_number", data?.insurance_policy_number);
    const fileFields = [
      "rto_document",
      "rto_tax_receipt",
      "mmr",
      "invoice",
      "insurance",
      "vehicle_image",
      "doh",
      "chasis_number_img",
      "motor_controller",
      "battery_image",
      "registration_certificate",
      "warranty_card",
      "bank_statement",
    ];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }
    const VsignedChequeFields = [
      "signed_cheque_1",
      "signed_cheque_2",
      "signed_cheque_3",
      "signed_cheque_4",
    ];

    VsignedChequeFields.forEach((field, index) => {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(`vehicle_signed_cheque[${index}]`, file);
        }
      }
    });
    const signedChequeFields = ["nor_sign_1", "nor_sign_2"];

    signedChequeFields.forEach((field, index) => {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(`signed_cheque[${index}]`, file);
        }
      }
    });

    const otherBorDoc = [];

    const otherBorDocFields = [
      "otherDoc1",
      "otherDoc2",
      "otherDoc3",
      "otherDoc4",
    ];
    otherBorDocFields.forEach((field, index) => {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(`vehicle_other_docs[${index}]`, file);
        }
      }
    });
    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    try {
      const apiEndpoint =
        proId === "4"
          ? `${baseURL}admin/upload-battery-document`
          : `${baseURL}admin/upload-vehicle-document`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      //  console.log("Form submitted successfully:", response.data);
      setActiveStep(25);
    } catch (error) {
      //   console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;
      //  console.log("message", message);
      showToast(message, "error");
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {" "}
      <p className={classes.applheader}>Vehicle Document</p>
      <div className={classes.totalcheckconnnforimgsec}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Down Payment Amount
            <span className={classes.requ}>*</span>
          </label>
          <input
            {...register("down_payment", { required: true })}
            type="number"
            step="0.01"
            className={classes.inputstst}
          />

          {errors.down_payment && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Vehicle Image
              <span className={classes.requ}>*</span>
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg"
              {...register("vehicle_image", { required: true })}
              type="file"
              className={classes.uploadbx}
            />

            {errors.vehicle_image && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              RTO Document<span className={classes.requ}>*</span>
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg"
              {...register("rto_document", { required: true })}
              type="file"
              className={classes.uploadbx}
            />

            {errors.rto_document && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              RTO Tax Receipt<span className={classes.requ}>*</span>
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg"
              {...register("rto_tax_receipt", { required: true })}
              type="file"
              className={classes.uploadbx}
            />

            {errors.rto_tax_receipt && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Insurance Policy Number
              <span className={classes.requ}>*</span>
            </label>
            <input
              {...register("insurance_policy_number", { required: true })}
              type="text"
              className={classes.inputstst}
            />

            {errors.insurance_policy_number && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}

        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Insurance<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("insurance", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg , application/pdf"
              className={classes.uploadbx}
            />

            {errors.insurance && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}

        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Invoice<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("invoice", { required: true })}
            type="file"
            accept="image/jpeg, image/png,image/jpg , application/pdf"
            className={classes.uploadbx}
          />

          {errors.invoice && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>

        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              MMR
              <span className={classes.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Name"
              {...register("mmr", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg , application/pdf"
              className={classes.uploadbx}
            />

            {errors.mmr && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              DOH
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg , application/pdf"
              {...register("doh")}
              type="file"
              className={classes.uploadbx}
            />
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Chassis Number
              <span className={classes.requ}>*</span>
            </label>
            <input
              {...register("chasis_number", { required: true })}
              type="text"
              className={classes.inputstst}
            />

            {errors.chasis_number && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Chassis Number Image<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("chasis_number_img", { required: true })}
              accept="image/jpeg, image/png,image/jpg"
              type="file"
              className={classes.uploadbx}
            />

            {errors.chasis_number_img && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Battery Image<span className={classes.requ}>*</span>
              <span className={classes.requ} style={{ fontSize: "9px" }}>
                (Collage Accepted)
              </span>
            </label>
            <input
              {...register("battery_image", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg "
              className={classes.uploadbx}
            />

            {errors.battery_image && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Motor Controller<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("motor_controller", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg "
              className={classes.uploadbx}
            />

            {errors.motor_controller && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Registration Certificate<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("registration_certificate", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg , application/pdf"
              className={classes.uploadbx}
            />

            {errors.registration_certificate && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId === "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Warranty Card<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("warranty_card", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg "
              className={classes.uploadbx}
            />

            {errors.warranty_card && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId === "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Bank Statement<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("bank_statement", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg  , application/pdf"
              className={classes.uploadbx}
            />

            {errors.bank_statement && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Vehicle Signed Cheque 1<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("signed_cheque_1", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg "
              className={classes.uploadbx}
            />

            {errors.signed_cheque_1 && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Vehicle Signed Cheque 2<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("signed_cheque_2", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg "
              className={classes.uploadbx}
            />

            {errors.signed_cheque_2 && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId === "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Signed Cheque 1<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("nor_sign_1", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg "
              className={classes.uploadbx}
            />

            {errors.nor_sign_1 && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId === "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Signed Cheque 2<span className={classes.requ}>*</span>
            </label>
            <input
              {...register("nor_sign_2", { required: true })}
              type="file"
              accept="image/jpeg, image/png,image/jpg "
              className={classes.uploadbx}
            />

            {errors.nor_sign_2 && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Other Vehicle Document 1
            </label>
            <input
              {...register("otherDoc1")}
              type="file"
              className={classes.uploadbx}
              accept="image/jpeg, image/png,image/jpg "
            />
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Other Vehicle Document 2
            </label>
            <input
              {...register("otherDoc2")}
              type="file"
              className={classes.uploadbx}
              accept="image/jpeg, image/png,image/jpg "
            />
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Other Vehicle Document 3
            </label>
            <input
              {...register("otherDoc3")}
              type="file"
              className={classes.uploadbx}
              accept="image/jpeg, image/png,image/jpg "
            />
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Other Vehicle Document 4
            </label>
            <input
              {...register("otherDoc4")}
              type="file"
              className={classes.uploadbx}
              accept="image/jpeg, image/png,image/jpg "
            />
          </div>
        )}
      </div>
      <div
        className={classes.buttonasc}
        style={{ marginTop: "10px", textAlign: "left" }}
      >
        {/* {!defaultValue && ( */}
        <button
          type="submit"
          className={classes.Subbtn}
          style={{ width: "90px", marginTop: "10px" }}
        >
          Submit
        </button>
        {/* )} */}
      </div>
    </form>
  );
};

export default VehicDoc;
