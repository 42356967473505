import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Language.module.css";
import axios from "axios";
import api from "../../Common/api";
import { AuthContext } from "../../../context/AuthContext";
import { LoanContext } from "../../../context/LoanContext";
import { useToast } from "../../../context/ToastProvder";
const Step19 = () => {
  const { borrId, userId, userD, handleNext, setVName, setVMob } =
    useContext(LoanContext);

  const { showToast } = useToast();
  const { token, baseURL, baseDoc, ver } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const HandleShow = () => {
    setShow(true);
  };

  const {
    register,

    handleSubmit,

    watch,
    formState: { errors },
  } = useForm();

  //   const VName = watch("name");
  //   const VMob = watch("mobile");
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          console.log("Loan Status:", response.data);

          const { product_id, step_details } = response?.data;
          const { step } = step_details;

          // setProductId(product_id);

          // if (step === 18) {
          //   setInitialStep(true);
          // }
        })
        .catch((error) => {
          console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("borrower_id", borrId);
    formData.append("user_id", userD?.id || userId);
    formData.append("name", data.name);
    formData.append("mobile", data.mobile);
    setVName(data.name);
    setVMob(data.mobile);

    try {
      const apiEndpoint = `${baseURL}${ver}admin-generate-vkyc-url`;
      // const apiEndpoint = `${baseURL}${ver}generate-vkyc-url`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      // console.log("Form submitted successfully:", response.data);
      handleNext();
    } catch (error) {
      //  console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;
      //  console.log("message", message);
      showToast(message, "error");
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.tablco}>
        <p className={classes.applheader}>VIDEO KYC</p>
        <div className={classes.totalcheckconnn}>
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Name<span className={classes.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Name"
              {...register("name", {
                required: true,
                validate: (value) =>
                  /^[A-Za-z\s]+$/.test(value) ||
                  "Only letters and spaces are allowed.",
              })}
              type="text"
              className={classes.inputstst}
            />

            {errors.name && (
              <span className={classes.error}>
                {errors.name.message || "This Field is Missing."}
              </span>
            )}
          </div>

          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Mobile Number<span className={classes.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Mobile Number"
              {...register("mobile", { required: true })}
              type="number"
              className={classes.inputstst}
            />

            {errors.mobile && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        </div>
        <div
          className={classes.buttonasc}
          style={{ marginTop: "10px", textAlign: "left" }}
        >
          {/* {!defaultValue && ( */}
          <button
            type="submit"
            className={classes.Subbtn}
            style={{ width: "90px", marginRight: "12px" }}
          >
            Submit
          </button>
        </div>
      </form>

      {/* {intitialStep && <AdditionalTwel VName={VName} VMob={VMob} />} */}
    </div>
  );
};

export default Step19;
