import React, { createContext, useState } from "react";

export const LoanContext = createContext();

export const LoanProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [userD, setUserD] = useState(null);
  const [userId, setUserId] = useState(null);
  const [borrwD, setBorrowD] = useState(null);
  const [borrId, setBorrId] = useState(null);
  const [dealerId, setdealerId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [proId, setProId] = useState(null);
  const [bbData, setbbData] = useState(null);
  const [VName, setVName] = useState(null);
  const [VMob, setVMob] = useState(null);
  const [dataFromAdhar, setDataFromAdhar] = useState(null);
  const [dataFromAdharG, setDataFromAdharG] = useState(null);
  const [dataFromAdharCo, setDataFromAdharCo] = useState(null);
  const [skipCoApp, setSkipCoApp] = useState(false);
  const [showGen, setShowGen] = useState(true);
  const [showRegenButton, setShowRegenButton] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <LoanContext.Provider
      value={{
        activeStep,
        setActiveStep,
        userD,
        setUserD,
        borrwD,
        setBorrowD,
        borrId,
        setBorrId,
        dataFromAdhar,
        setDataFromAdhar,
        skipCoApp,
        setSkipCoApp,
        handleNext,
        dataFromAdharCo,
        setDataFromAdharCo,
        userId,
        setUserId,
        dataFromAdharG,
        setDataFromAdharG,
        dealerId,
        setdealerId,
        productId,
        setProductId,
        bbData,
        setbbData,
        VName,
        setVName,
        VMob,
        setVMob,
        showGen,
        setShowGen,
        showRegenButton,
        setShowRegenButton,
      }}
    >
      {children}
    </LoanContext.Provider>
  );
};
