import React, { useState } from "react";
import classes from "../Borrower/Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { IoMdAdd } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import Table from "./Table";
import PaginationComponent from "../../Common/PaginationComponent";
import { Backdrop } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import Loader from "../../Common/Loader";
import SearchComp from "./SearchComp";
import { useLocation, useNavigate } from "react-router-dom";

const DAdress = () => {
  const [perm, setPerm] = useState([]);
  const [page, setPage] = useState(1);
  const [add, setAdd] = useState(false);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [totalPage, setTotalPage] = useState(2);
  const [searchData, setSearchData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const ShowSearchHandler = (event, value) => {
    setShowSearch((val) => !val);
  };
  const searchDataHandler = (data) => {
    setSearchData(data);
    setPage(1);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const location = useLocation();
  const { val } = location.state || "";
  const navigate = useNavigate();
  const addHandle = () => {
    navigate("/add-address-details", {
      state: { val },
    });
  };
  //  navigate("/add-address-details", {
  //    state: { row },
  //  });
  console.log("val", val);
  const fetchData = async ({ page, numberOfRecords, searchData }) => {
    // const payload = {
    //   page,
    //   number_of_records: numberOfRecords,
    // };

    // if (searchData) {
    //   payload.department = searchData.bankId || "";
    // }

    const response = await api.get(
      `admin/get-dealer-shops/${val?.id}`
      //   , {
      //   params: payload,
      // }
    );
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["langList", page, numberOfRecords, searchData],
    queryFn: () =>
      fetchData({
        page,
        numberOfRecords,
        searchData,
      }),

    // onSuccess: (data) => {
    //   // data?.allBankDetailsList.sort((a, b) =>
    //   //   a.bank_name.localeCompare(b.bank_name)
    //   // );
    //   setTotalPage(data.data?.last_page);
    // },
  });
  const handleRecordsChange = (event) => {
    setNumberOfRecords(event.target.value);
  };
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("dashboardList", dashboardList);
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/3");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  console.log("perm", perm);
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      {perm?.includes("read") && (
        <div className={classes.mainBodyContainer}>
          <Breadcum pageName={`Shop Details of ${val?.name}`} />
          <div className={classes["main-body"]} style={{ padding: "15px" }}>
            <div className={classes.tablco}>
              {showSearch && <SearchComp onSearch={searchDataHandler} />}
              <div className={classes.inputsearchcon}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <select
                    onChange={handleRecordsChange}
                    value={numberOfRecords}
                    className={classes.selectfotnu}
                  >
                    {["10", "20", "50", "100"].map((value) => (
                      <option
                        key={value}
                        style={{ fontSize: "11px" }}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                  {perm?.includes("create") && (
                    <button
                      type="button"
                      className={classes.addbtn}
                      onClick={addHandle}
                    >
                      <IoMdAdd
                        size={11}
                        style={{
                          paddingRight: "5px",
                          position: "relative",
                          top: "1px",
                        }}
                      />
                      Add
                    </button>
                  )}
                </div>
                <div></div>
              </div>
              <div className={classes.tableContai}>
                {isLoading || isFetching ? (
                  <Loader />
                ) : (
                  <Table
                    rowData={dashboardList?.data || []}
                    page={page}
                    numberOfRecords={numberOfRecords}
                    perm={perm}
                    val={val}
                  />
                )}
              </div>
              <div className={classes.paginCont}>
                {/* <p className={classes.pagitext}>
                  {" "}
                  Showing {dashboardList?.data?.from} to{" "}
                  {dashboardList?.data?.to} of {dashboardList?.data?.total}{" "}
                  entries.
                </p> */}
                {/* <PaginationComponent
                  pageChangeHandler={handleChange}
                  pageState={page}
                  totalPageNo={totalPage}
                /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DAdress;
