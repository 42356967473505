import React, { useContext, useEffect, useState } from "react";
import classes from "./Language.module.css";
import { useForm } from "react-hook-form";
import { useToast } from "../../../context/ToastProvder";
import { AuthContext } from "../../../context/AuthContext";
import axios from "axios";
import { LoanContext } from "../../../context/LoanContext";
import api from "../../Common/api";
const Step12 = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [proId, setProId] = useState(null);
  const [aaF, setAaF] = useState(false);
  const [aaB, setAaB] = useState(false);
  const [pa, setPa] = useState(false);
  const [aaFDoc, setAaFDoc] = useState(null);
  const [aaBDoc, setAaBDoc] = useState(null);
  const [paDoc, setPaDoc] = useState(null);
  const { borrId, handleNext, userD, userId } = useContext(LoanContext);
  const { showToast } = useToast();
  const { token, baseURL, ver } = useContext(AuthContext);

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("borrower_id", borrId);
    formData.append("aadhar_front", aaFDoc);
    formData.append("aadhar_back", aaBDoc);
    formData.append("pan_card", paDoc);
    const fileFields = [
      // "aadhar_front",
      // "aadhar_back",
      // "pan_card",
      "photo",
      "electric_bill",
      "driving_license_img",
      "urc_certificate",
      // "signed_cheque_1",
      // "signed_cheque_2",
      // "signed_cheque_3",
      // "signed_cheque_4",
    ];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    // if (oversizedFiles.length > 0) {
    //   const fieldNames = oversizedFiles.join(", ");
    //   showToast(
    //     `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
    //     "error"
    //   );
    //   return;
    // }
    const signedChequeFields = [
      "signed_cheque_1",
      "signed_cheque_2",
      "signed_cheque_3",
      "signed_cheque_4",
    ];

    signedChequeFields.forEach((field, index) => {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(`borrower_signed_cheque[${index}]`, file);
        }
      }
      // else {
      //   formData.append(`borrower_signed_cheque[${index}]`, "");
      // }
    });

    const otherBorDoc = [];

    const otherBorDocFields = [
      "otherDoc1",
      "otherDoc2",
      "otherDoc3",
      "otherDoc4",
    ];
    otherBorDocFields.forEach((field, index) => {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(`borrower_other_docs[${index}]`, file);
        }
      }
    });
    // otherBorDocFields.forEach((field, index) => {
    //   // Handle file inputs
    //   if (data[field] && data[field].length > 0) {
    //     const file = data[field][0];
    //     if (file.size > MAX_FILE_SIZE) {
    //       oversizedFiles.push(field);
    //     } else {
    //       formData.append(`borrower_other_docs[${index}]`, file);
    //     }
    //   } else {
    //     formData.append(`borrower_other_docs[${index}]`, "");
    //   }
    // });

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    try {
      // const apiEndpoint = `${baseURL}admin/upload-borrower-document`;
      const apiEndpoint =
        proId === "4"
          ? `${baseURL}admin/upload-borrower-battery-document`
          : `${baseURL}admin/upload-borrower-document`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      // console.log("Form submitted successfully:", response.data);
      handleNext();
    } catch (error) {
      //  console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;

      showToast(message, "error");
    }
  };
  const handleAdharCardChange = async (event) => {
    const file = event.target.files[0];
    setAaFDoc(file);
    if (file) {
      const formData = new FormData();
      formData.append("user_id", userD?.id || userId);
      formData.append("borrower_id", borrId);
      formData.append("aadhar_image", file);
      formData.append("user_type", "BORROWER");

      try {
        const apiEndpoint = `${baseURL}${ver}admin-aadhar-ocr-check`;
        // const apiEndpoint = `${baseURL}${ver}aadhar-ocr-check`;
        const response = await axios.post(apiEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        setAaF(true);
        const { status, message } = response.data;
        showToast(message, status === "success" ? "success" : "error");
        //  console.log("PAN OCR check successful:", response.data);
      } catch (error) {
        //   console.error("Error in PAN OCR check:", error);
        const { message } = error.response.data;
        showToast(message, "error");
        setAaF(false);
      }
    }
  };
  const handleAdharCardBChange = async (event) => {
    const file = event.target.files[0];
    setAaBDoc(file);
    if (file) {
      const formData = new FormData();
      formData.append("user_id", userD?.id || userId);
      formData.append("borrower_id", borrId);
      formData.append("aadhar_image", file);
      formData.append("user_type", "BORROWER");

      try {
        const apiEndpoint = `${baseURL}${ver}admin-aadhar-ocr-check`;
        const response = await axios.post(apiEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        setAaB(true);
        const { status, message } = response.data;
        showToast(message, status === "success" ? "success" : "error");
        //    console.log("PAN OCR check successful:", response.data);
      } catch (error) {
        //  console.error("Error in PAN OCR check:", error);
        const { message } = error.response.data;
        showToast(message, "error");
        setAaB(false);
      }
    }
  };
  const handlePanCardChange = async (event) => {
    const file = event.target.files[0];
    setPaDoc(file);
    if (file) {
      const formData = new FormData();
      formData.append("user_id", userD?.id || userId);
      formData.append("borrower_id", borrId);
      formData.append("pan_image", file);
      formData.append("user_type", "BORROWER");

      try {
        const apiEndpoint = `${baseURL}${ver}admin-pan-ocr-check`;
        const response = await axios.post(apiEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        setPa(true);
        const { status, message } = response.data;
        showToast(message, status === "success" ? "success" : "error");
        //   console.log("PAN OCR check successful:", response.data);
      } catch (error) {
        //   console.error("Error in PAN OCR check:", error);
        const { message } = error.response.data;
        setPa(false);
        showToast(message, "error");
      }
    }
  };
  useEffect(() => {
    if (borrId) {
      api
        .post("admin/get-loan-status", {
          borrower_id: borrId,
        })
        .then((response) => {
          //   console.log("Loan Status:", response.data);

          const { product_id } = response?.data;
          const { step } = response?.data?.step_details;
          setProId(product_id);
        })
        .catch((error) => {
          //    console.error("Error fetching loan status:", error);
        });
    }
  }, [borrId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.tablco}>
      <p className={classes.applheader}>Borrower Documents</p>
      <div className={classes.totalcheckconnnforimgsec}>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Image
            <span className={classes.requ}>*</span>
          </label>
          <input
            accept="image/jpeg, image/png,image/jpg"
            {...register("photo", { required: true })}
            type="file"
            className={classes.uploadbx}
          />

          {errors.photo && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Front<span className={classes.requ}>*</span>
          </label>
          <input
            accept="image/jpeg, image/png,image/jpg"
            {...register("aadhar_front", { required: true })}
            type="file"
            className={classes.uploadbx}
            onChange={handleAdharCardChange}
          />

          {errors.aadhar_front && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            Aadhar Back<span className={classes.requ}>*</span>
          </label>
          <input
            accept="image/jpeg, image/png,image/jpg"
            {...register("aadhar_back", { required: true })}
            type="file"
            className={classes.uploadbx}
            onChange={handleAdharCardBChange}
          />

          {errors.aadhar_back && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        <div className={classes.searorinp}>
          <label
            style={{
              fontSize: "12px",
              color: "#212529",
              fontWeight: "bold",
            }}
          >
            PAN Card<span className={classes.requ}>*</span>
          </label>
          <input
            {...register("pan_card", { required: true })}
            type="file"
            accept="image/jpeg, image/png,image/jpg"
            className={classes.uploadbx}
            onChange={handlePanCardChange}
          />

          {errors.pan_card && (
            <span className={classes.error}>This Field is Missing.</span>
          )}
        </div>
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              URC Certificate
            </label>
            <input
              {...register("urc_certificate")}
              type="file"
              accept="image/jpeg, image/png,image/jpg , application/pdf"
              className={classes.uploadbx}
            />
          </div>
        )}
        {/* {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Landownership
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg  , application/pdf"
              // {...register("electric_bill")}
              type="file"
              className={classes.uploadbx}
            />
          </div>
        )} */}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Electricity Bill
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg  , application/pdf"
              {...register("electric_bill")}
              type="file"
              className={classes.uploadbx}
            />
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Driving Licence
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg"
              {...register("driving_license_img")}
              type="file"
              className={classes.uploadbx}
            />
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Signed Cheque 1<span className={classes.requ}>*</span>
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg"
              {...register("signed_cheque_1", { required: true })}
              type="file"
              className={classes.uploadbx}
            />
            {errors.signed_cheque_1 && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Signed Cheque 2<span className={classes.requ}>*</span>
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg"
              {...register("signed_cheque_2", { required: true })}
              type="file"
              className={classes.uploadbx}
            />
            {errors.signed_cheque_2 && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Signed Cheque 3<span className={classes.requ}>*</span>
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg"
              {...register("signed_cheque_3", { required: true })}
              type="file"
              className={classes.uploadbx}
            />
            {errors.signed_cheque_3 && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Signed Cheque 4<span className={classes.requ}>*</span>
            </label>
            <input
              accept="image/jpeg, image/png,image/jpg"
              {...register("signed_cheque_4", { required: true })}
              type="file"
              className={classes.uploadbx}
            />
            {errors.signed_cheque_4 && (
              <span className={classes.error}>This Field is Missing.</span>
            )}
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Other Documents 1
            </label>
            <input
              {...register("otherDoc1")}
              accept="image/jpeg, image/png,image/jpg"
              type="file"
              className={classes.uploadbx}
            />
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Other Documents 2
            </label>
            <input
              {...register("otherDoc2")}
              accept="image/jpeg, image/png,image/jpg"
              type="file"
              className={classes.uploadbx}
            />
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Other Documents 3
            </label>
            <input
              {...register("otherDoc3")}
              accept="image/jpeg, image/png,image/jpg"
              type="file"
              className={classes.uploadbx}
            />
          </div>
        )}
        {proId !== "4" && (
          <div className={classes.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Other Documents 4
            </label>
            <input
              {...register("otherDoc4")}
              accept="image/jpeg, image/png,image/jpg"
              type="file"
              className={classes.uploadbx}
            />
          </div>
        )}
      </div>
      {aaB && aaF && pa && (
        <div
          className={classes.buttonasc}
          style={{ marginTop: "10px", textAlign: "left" }}
        >
          <button
            type="submit"
            className={classes.Subbtn}
            style={{ width: "90px", marginTop: "10px" }}
          >
            Submit
          </button>
        </div>
      )}
    </form>
  );
};

export default Step12;
