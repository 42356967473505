import React, { useContext, useState } from "react";
import profl from "./../../../icons/profl.jpg";
import styles from "./SeprateDet.module.css";
import { Backdrop } from "@mui/material";
import Modal from "./Modal";
import { FaFileDownload } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import EmiChart from "../Borrower/EmiChart";
import api from "../../Common/api";
import { useToast } from "../../../context/ToastProvder";
const BorrowerDetails = ({ Detailsb, borrowerId, perm }) => {
  const { user, token, setUser, setToken, baseURL, baseDoc } =
    useContext(AuthContext);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [add, setAdd] = useState(false);
  const [chart, setChart] = useState(false);
  console.log("Detailsb", Detailsb);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEmiCh = (rowIndex) => {
    setRowToEdit(rowIndex);
    setChart((val) => !val);
  };
  const chartHandle = () => {
    setChart((val) => !val);
  };
  const navigate = useNavigate();
  const EditHandle = () => {
    navigate("/edit-borrower-documents", {
      state: { borrowerId },
    });
  };
  const { showToast } = useToast();
  const handleDownloadBureau = async () => {
    try {
      const response = await api.get(
        `admin/get-bureau-report-download-link/${borrowerId}/BORROWER`
      );

      if (
        response.data.status === "success" &&
        response.data.data.download_url
      ) {
        const downloadUrl = response.data.data.download_url;
        console.log("downloadUrl", downloadUrl);

        const fileResponse = await fetch(downloadUrl);
        const blob = await fileResponse.blob();

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "BureauReportBorrower.xlsx");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
        const successMessage = response.data.message || "Download successful!";
        showToast(successMessage, "success");
      } else {
        console.error("Error: Download URL not found in response.");
      }
    } catch (error) {
      console.error("Error downloading the report:", error);
      const { message, errors } = error.response.data;
      console.log("message", message);
      showToast(message, "error");
    }
  };

  console.log("Detailsb in brwdd", Detailsb);
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <Modal
          addHandle={addHandle}
          doc={Detailsb?.documents || {}}
          type="borrower"
          borrowerId={borrowerId}
        />
      </Backdrop>
      {chart && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={chart}
          onClick={chartHandle}
        >
          <EmiChart addHandle={chartHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
      )}
      <div style={{ textAlign: "right" }}>
        {/* <button
          style={{
            padding: "5px 7px",
            color: "#fff",
            background: "#f06548",
            border: "none",
            margin: "8px",
            borderRadius: "4px",
            fontSize: "11px",
            height: "24px",
            position: "relative",
            top: "3px",
          }}
          onClick={EditHandle}
        >
          <BiSolidEdit
            size="14"
            // style={{ position: "relative", top: "3px" }}
          />{" "}
          <span style={{ position: "relative", bottom: "3px" }}>
            {" "}
            Edit Borrower Document
          </span>
        </button> */}
        {perm?.includes("export") && (
          <button
            style={{
              margin: "8px",
            }}
            className={styles.dnldbtnforreport}
            onClick={handleDownloadBureau}
          >
            Download Bureau Report
          </button>
        )}

        <button
          style={{
            margin: "8px",
          }}
          className={styles.dnldbtnforreport}
          onClick={addHandle}
        >
          View Document
        </button>
      </div>
      <div className={styles.brwdettcon}>
        <img
          src={
            Detailsb?.documents?.borrower_avtar
              ? `${baseDoc}${Detailsb.documents.borrower_avtar}`
              : profl
          }
          style={{ width: "100px", borderRadius: "5px", height: "100px" }}
        />
        <div className={styles.wholetxtcon}>
          <div className={styles.totalchechyjr}>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Borrower Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.name || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Borrower ID</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.loan_details?.borrower_id || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Created On</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_details?.created_at || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Mobile No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.mobile || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Date Of Birth</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.dob_date || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Marital Status</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.marital_status || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Spouse's Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.spouse_name || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Father's Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.father_name || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Gender</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.gender || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Alternate Number</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.mobile || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Email Id</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.email || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>PAN No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.pan || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Aadhar No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.aadhar || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Driving Licence No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.driving_license_no || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>State</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.address_details?.full_address
                  ?.state || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Country</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_address?.address_details?.full_address
                  ?.country || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>CIBIL</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.credit_details?.borrower_cibil_score || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Postal</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.address_details?.full_address
                  ?.pc || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Loan Amount</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_details?.loan_amount || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Down Payment</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_details?.downpayment || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Loan ID</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.disbursement_details?.loan_id || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>No of Earning Members</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_address?.earning_members || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Family Members</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.family_members || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Family Income</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.family_income || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Resident type</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_address?.resident_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Years Living In Resident</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_address?.years_living || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>City</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.address_details?.city || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Current Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.current_address || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Current Occupation</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.current_occupation || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Expected Monthly Earning</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_occupation?.expected_monthly_earning || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Extra Source</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.extra_source_income || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Monthly Earning</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.monthly_earning || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Occupation Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.occupation_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Total Income</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_occupation?.total_income || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Source</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.source_income_area || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Vehicle Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.vehicle_type || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Permanent Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.permanent_address || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Vehicle ID</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.iot_details?.vehicle_id || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>IOT Status</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.iot_details?.status ? "True" : "false" || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Vehicle UUID</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.iot_details?.vehicle_uuid || ""}
              </p>
            </div>

            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Policy Number</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_details?.policy_number || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Chasis Number</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_details?.chasis_number || ""}
              </p>
            </div>
          </div>
          {/* <p
            className={styles.ptpfrtxt}
            style={{
              borderBottom: "2px solid #f06548",
              fontSize: "17px",

              paddingTop: "10px",
              color: "#656878",
            }}
          >
            BANK DETAILS
          </p> */}
          <div className={styles.btndvfettoop}>
            <span className={styles.btndvtxt} style={{ color: "#fff" }}>
              {" "}
              BANK DETAILS
            </span>
          </div>
          <div className={styles.totalchechyjr}>
            {" "}
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Account Holder Name</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.account_details?.account_holder_name}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Account Number</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.account_details?.account_number || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Bank Name</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.account_details?.bank_name}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>EMI Debit Consent</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.account_details?.emi_debit_consent}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>IFSC Code</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.account_details?.ifsc || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Branch Code</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.account_details?.branch_code || "-"}
              </p>
            </div>
          </div>
          {/* <p
            className={styles.ptpfrtxt}
            style={{
              borderBottom: "2px solid #f06548",
              fontSize: "17px",
              paddingTop: "10px",
              color: "#656878",
            }}
          >
            LOAN SCHEME
          </p> */}
          <div className={styles.btndvfettoop}>
            <span className={styles.btndvtxt} style={{ color: "#fff" }}>
              {" "}
              LOAN SCHEME
            </span>
          </div>
          <div className={styles.totalchechyjr}>
            {" "}
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Product Name</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.product_name}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Product UNQ</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.product_unq}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Ex Showroom Price</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.ex_showroom_price}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Loan to value Ratio</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.loan_to_value_ratio}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Min Loan Amount</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.min_loan_amount}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Max Loan Amount</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.max_loan_amount}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Min Loan Tenure</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.min_loan_tenure}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Max Loan Tenure</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.max_loan_tenure}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Interest</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.interest}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Processing Fee</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.processing_fee}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Subvention Amount</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.subvention_amount}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>FLDG</p>
              <p className={styles.pbtfrtxt}> {Detailsb?.loan_scheme?.fldg}</p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>IOT</p>
              <p className={styles.pbtfrtxt}> {Detailsb?.loan_scheme?.iot}</p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>1st Year Insurance</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.insurance_1st_year}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>2nd Year Insurance</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.insurance_2nd_year}
              </p>
            </div>
            <div className={styles.actcontfrtxtxtr}>
              <p className={styles.ptpfrtxt}>Down Payment Amount</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_scheme?.down_payment_amount}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BorrowerDetails;
